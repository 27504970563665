<!-- <div class="modal-header">
	<h4 class="modal-title">Hi there!</h4>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
</div>
<div class="modal-body">
	<div class="card lesson-border">
		<img class="card-img-top" src="{{lesson.image_path}}" alt="Card image cap">
		<div class="card-body">
			<p class="card-text"><b>Date: </b>{{lesson.startdate}}</p>
			<p class="card-text"><b>Week: </b>{{lesson.week}}</p>
			<h5 class="card-title"><b>Title: </b>{{lesson.title}}</h5>
			<p class="card-text"><b>Description: </b>{{lesson.description}}</p>
			<p class="card-text"><b>Category: </b>{{lesson.category}}</p>
			<p class="card-text"><b>Content: </b>{{lesson.lessoncontent}}</p>
		</div>
	</div>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div> -->

<div class="popup-container">
    <div class="popup-header">
        <!-- <img alt="" src="../../../assets/images/msLogo.png"> -->
        <h4 class="product-name">{{productVariant.name}}</h4>
    </div>
    <div class="header-alert">
        <img alt="" class="header-alert-icon" src="../../../assets/icons/ProductLoadingIcon.svg">
    </div>
    <ng-container *ngIf="productVariant.isAddOn">
        <div class="popup-body">
            <div class="body-description">
                The product comes with the below AddOns.
            </div>
            <div class="body-content">
                <h6>Please select the ones you already have.</h6>
                <ng-container *ngFor="let item of requiredAddOnsList">
                    <label class="item-list" for="checkboxId">
                        <input id="checkboxId" class="checkbox-input" type="checkbox" name="checkboxitemname" [(ngModel)]="item.checked"/>
                        <div class="check-box"></div>
                        <span class="item-name">{{item.name}}</span>
                    </label>
                    
                </ng-container>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!productVariant.isAddOn">
        <div class="popup-body">
            Please verify the information and confirm.
        </div>
    </ng-container>
    <div class="popup-footer">
        <button class="btn secondary-alert-btn" (click)="closeModal()">Cancel</button>
        <button class="btn primary-alert-btn" (click)="requestQuote()">Request quote</button>
    </div>
</div>