import { Component } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
 
  
  isHiddenTwitter = false;
  isHiddenLinkedIn = false;
  isHiddenYouTube = false;

  

  
}
