<div class="modal-container">
    <div class="modal-header">
        <span>
            Assign Leads
        </span>
    </div>
    <div class="modal-body">

        <div #assignTo class="field-holder">
            <div class="left-div">
                <span>
                    Assign To 
                    <!-- <mat-icon>info</mat-icon> -->
                </span>
            </div>
            <div class="right-div">
                <ng-container *ngIf="channelPartnerList && channelPartnerList.length>0">
                <div class="card flex justify-content-center">

                    <p-dropdown placeholder="Select Channel" [options]="channelPartnerList" (onChange)="onChange($event)" optionLabel="channelPartnerMaster.name"></p-dropdown>
                </div>
            </ng-container>
            </div>
        </div>

        <div #assignDate class="field-holder">
            <div class="left-div">
                <span>
                    Target Date
                    <!-- <mat-icon>info</mat-icon> -->
                </span>
            </div>
            <div class="right-div">
                <div class="card flex justify-content-center">
                    <!-- <p-calendar [(ngModel)]="closingDate"[minDate]="yesterday " [readonlyInput]="true" [showIcon]="true"></p-calendar> -->
              
                <p-calendar [(ngModel)]="closingDate" [minDate]="today" [readonlyInput]="true" [showIcon]="true"></p-calendar>

                </div>
            </div>
        </div>

        
    </div>
    
    <div class="modal-footer">

        <!-- <div class="btn-holder"> -->
            <button class="btn secondary-btn"  (click)="onCancelClick()">Cancel</button>
            <button id="assign-btn" class="btn primary-btn" (click)="submit()">Assign</button>
        <!-- </div> -->
        <!-- <button (click)="submit()">Submit</button> -->
    </div>
    <ngx-spinner size="medium" type="ball-atom"></ngx-spinner>
</div>