import { Component } from '@angular/core';

@Component({
  selector: 'searchbar-recently-searched',
  templateUrl: './searchbar-recently-searched.component.html',
  styleUrls: ['./searchbar-recently-searched.component.css']
})
export class SearchbarRecentlySearchedComponent {

}
