<div class="reason-container">
  <!-- <div class="close-btn-section"> -->
 
<!-- </div> -->
  <div class="modal-header">
    <span>
        Update Your Comments
    </span>
    <div class="close-btn-section">
      <button (click)="closeModal()" type="button" class="close" aria-label="Close">
          <img class="close-icon" src="../../../../assets/icons/closecircle.svg">
      </button>
  </div>
   
</div>

    
    <form [formGroup]="form">
        <div class="message-text-style">
            <span class="p-float-label">
              <textarea 
                id="messageText" 
                formControlName="messageText"
                pInputTextarea></textarea>
              <label for="messageText">Message</label>
              <div *ngIf="submitted && f.messageText.errors">
                <div *ngIf="submitted && f.messageText.errors.required" class="error-message">Reason is required</div>
              </div>
            </span>
          </div>
    </form>

    <div class="button-section">
        <button class="btn primary-btn alert-btn" (click)="reAssign(false)">Reject</button>
        <button class="btn primary-btn" (click)="reAssign(true)">Reassign</button>
    </div>

</div>