<div id="product-review-container" *ngIf="productReviews">
    <div class="card">
        <div class="row justify-content-left d-flex">
            <div class="col-md-5 d-flex flex-column">
                <div>
                    <h1 class="pt-4"> {{calculateOverAllRating()}} / 5</h1>
                </div>
                <div>
                    <p class="">({{productReviews.length}} reviews)</p>
                </div>
                <div class="btn-holder">
                    <button class="btn primary-btn">See Reviews</button>
                    <button class="btn secondary-btn">Write Review</button>
                </div>
                
                <!-- <div>
                    <span class="fa fa-star star-active mx-1"></span>
                    <span class="fa fa-star star-active mx-1"></span>
                    <span class="fa fa-star star-active mx-1"></span>
                    <span class="fa fa-star star-active mx-1"></span>
                    <span class="fa fa-star star-inactive mx-1"></span>
                </div> -->
            </div>
            <div class="col-md-7">
                <div class="rating-bar0 justify-content-center">
                    <table class="text-left mx-auto">
						<ng-container *ngIf="reviewByValueList">
							<tr *ngFor="let item of reviewByValueList.slice().reverse()">
								<td class="rating-label">{{getratingDesc(item.val)}}</td>
								<td class="rating-bar">
									<div class="bar-container">
									<div class="bar-style" [style.width.%]="(item.totalReviewForVal/productReviews.length)*100"></div>
									</div>
								</td>
								<td class="text-right">{{item.totalReviewForVal}}</td>
							</tr>

						</ng-container>
                        <!-- <tr>
                            <td class="rating-label">Excellent</td>
                            <td class="rating-bar">
                                <div class="bar-container">
                                  <div class="bar-5"></div>
                                </div>
                            </td>
                            <td class="text-right">123</td>
                        </tr>
                        <tr>
                            <td class="rating-label">Good</td>
                            <td class="rating-bar">
                                <div class="bar-container">
                                  <div class="bar-4"></div>
                                </div>
                            </td>
                            <td class="text-right">23</td>
                        </tr>
                        <tr>
                            <td class="rating-label">Average</td>
                            <td class="rating-bar">
                                <div class="bar-container">
                                  <div class="bar-3"></div>
                                </div>
                            </td>
                            <td class="text-right">10</td>
                        </tr>
                        <tr>
                            <td class="rating-label">Poor</td>
                            <td class="rating-bar">
                                <div class="bar-container">
                                  <div class="bar-2"></div>
                                </div>
                            </td>
                            <td class="text-right">3</td>
                        </tr>
                        <tr>
                            <td class="rating-label">Terrible</td>
                            <td class="rating-bar">
                                <div class="bar-container">
                                  <div class="bar-1"></div>
                                </div>
                            </td>
                            <td class="text-right">0</td>
                        </tr> -->
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>



<!-- <div class="container-fluid px-1 py-5 mx-auto">
	<div class="row justify-content-center">
		<div class="col-xl-7 col-lg-8 col-md-10 col-12 text-center mb-5">
			<div class="card">
				<div class="row justify-content-left d-flex">
					<div class="col-md-4 d-flex flex-column">
						<div class="rating-box">
							<h1 class="pt-4">4.0</h1>
							<p class="">out of 5</p>
						</div>
						<div>
							<span class="fa fa-star star-active mx-1"></span>
							<span class="fa fa-star star-active mx-1"></span>
							<span class="fa fa-star star-active mx-1"></span>
							<span class="fa fa-star star-active mx-1"></span>
							<span class="fa fa-star star-inactive mx-1"></span>
						</div>
					</div>
					<div class="col-md-8">
						<div class="rating-bar0 justify-content-center">
							<table class="text-left mx-auto">
								<tr>
									<td class="rating-label">Excellent</td>
									<td class="rating-bar">
										<div class="bar-container">
									      <div class="bar-5"></div>
									    </div>
									</td>
									<td class="text-right">123</td>
								</tr>
								<tr>
									<td class="rating-label">Good</td>
									<td class="rating-bar">
										<div class="bar-container">
									      <div class="bar-4"></div>
									    </div>
									</td>
									<td class="text-right">23</td>
								</tr>
								<tr>
									<td class="rating-label">Average</td>
									<td class="rating-bar">
										<div class="bar-container">
									      <div class="bar-3"></div>
									    </div>
									</td>
									<td class="text-right">10</td>
								</tr>
								<tr>
									<td class="rating-label">Poor</td>
									<td class="rating-bar">
										<div class="bar-container">
									      <div class="bar-2"></div>
									    </div>
									</td>
									<td class="text-right">3</td>
								</tr>
								<tr>
									<td class="rating-label">Terrible</td>
									<td class="rating-bar">
										<div class="bar-container">
									      <div class="bar-1"></div>
									    </div>
									</td>
									<td class="text-right">0</td>
								</tr>
							</table>
						</div>
					</div>
				</div>
			</div>
			<div class="card">
				<div class="row d-flex">
					<div class="">
						<img class="profile-pic" src="https://i.imgur.com/V3ICjlm.jpg">
					</div>
					<div class="d-flex flex-column">
						<h3 class="mt-2 mb-0">Vikram jit Singh</h3>
						<div>
							<p class="text-left"><span class="text-muted">4.0</span>
							<span class="fa fa-star star-active ml-3"></span>
							<span class="fa fa-star star-active"></span>
							<span class="fa fa-star star-active"></span>
							<span class="fa fa-star star-active"></span>
							<span class="fa fa-star star-inactive"></span></p>
						</div>
					</div>
					<div class="ml-auto">
						<p class="text-muted pt-5 pt-sm-3">10 Sept</p>
					</div>
				</div>
				<div class="row text-left">
					<h4 class="blue-text mt-3">"An awesome activity to experience"</h4>
					<p class="content">If you really enjoy spending your vacation 'on water' or would like to try something new and exciting for the first time.</p>
				</div>
				<div class="row text-left">
					<img class="pic" src="https://i.imgur.com/kjcZcfv.jpg">
					<img class="pic" src="https://i.imgur.com/SjBwAgs.jpg">
					<img class="pic" src="https://i.imgur.com/IgHpsBh.jpg">
				</div>
				<div class="row text-left mt-4">
					<div class="like mr-3 vote">
						<img src="https://i.imgur.com/mHSQOaX.png"><span class="blue-text pl-2">20</span>
					</div>
					<div class="unlike vote">
						<img src="https://i.imgur.com/bFBO3J7.png"><span class="text-muted pl-2">4</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div> -->
