<div class="form-group search-box">
  <span class="search-icon"><img alt="SearchIcon" src="../../../assets/icons/Search.svg"></span>
  <input
    type="text"
    [placeholder]="placeholder"
    name="searchInput"
    [formControl]="searchInput"
    class="form-control" 
  />
  
      
</div>
