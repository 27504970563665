import { Component } from '@angular/core';

@Component({
  selector: 'write-review-flyer',
  templateUrl: './write-review-flyer.component.html',
  styleUrls: ['./write-review-flyer.component.css']
})
export class WriteReviewFlyerComponent {

}
