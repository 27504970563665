<!-- <app-toolbar (menuToogleEvent)='menuToogleEvent($event)'></app-toolbar> -->
<!-- <ng-container *ngIf="userLoggedInFlag">
  <span class="navigation-arrow" [ngClass]="(isExpanded ? 'arrow-position-long' : 'arrow-position-short')" (click)="menuExpansion(isExpanded, null)">
    <mat-icon mat-list-icon class="chevron-button">{{(isExpanded ? 'chevron_left' : 'chevron_right')}}
    </mat-icon>
  </span>
</ng-container> -->

<!-- <mat-sidenav-container autosize> -->
  <div style="background: white;">

 
<mat-sidenav-container>

  
 

  
      <mat-sidenav #sidenav mode="side" opened="isExpanded" [ngClass]="(menuToogled) ? '' : 'hide-nav'">
        
        <div>

        
        <mat-nav-list [ngClass]="(isExpanded) ? 'nav-expanded' : ''">
        
            <ng-container *ngIf="userSubMenu">
              <!-- <mat-list-item [routerLink]="['user-profile/profile-view']">
                <span *ngIf="isExpanded" class="sub-menu-item">Update Profile</span>
              </mat-list-item> -->
              <mat-list-item (click)="logout()">
                <span *ngIf="isExpanded" class="sub-menu-item">Logout</span>
              </mat-list-item>
            </ng-container>

          <!-- Dashboard Section -->
          <mat-list-item>
              <span class="icon-holder" (click)="navAction(isExpanded , 'dashboard')">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M7.58584 5.22297C8.01093 4.93893 8.45993 4.69733 8.92638 4.50012C10.0583 4.02155 10.6243 3.78227 11.3463 4.26094C12.0684 4.73961 12.0684 5.52326 12.0684 7.09056V8.70423C12.0684 10.2256 12.0684 10.9863 12.541 11.459C13.0137 11.9316 13.7744 11.9316 15.2958 11.9316H16.9094C18.4767 11.9316 19.2604 11.9316 19.7391 12.6537C20.2177 13.3757 19.9784 13.9417 19.4999 15.0736C19.3027 15.5401 19.0611 15.9891 18.777 16.4142C17.8905 17.741 16.6304 18.7751 15.156 19.3858C13.6817 19.9965 12.0595 20.1563 10.4943 19.845C8.92922 19.5336 7.49157 18.7652 6.36318 17.6368C5.2348 16.5084 4.46636 15.0708 4.15504 13.5057C3.84371 11.9405 4.0035 10.3183 4.61417 8.84395C5.22485 7.36964 6.259 6.10953 7.58584 5.22297Z" stroke="currentColor" stroke-width="1.5"/>
                  <path d="M14.085 4.11719C16.9202 4.84693 19.1523 7.07905 19.8821 9.9143" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                </svg>
                <!-- <img src="../../../../assets/YellowChalk/DashboardIcon.svg" title="Dashboard" alt="Dashboard"> -->
              </span>
              <span *ngIf="isExpanded" class="menu-item" (click)="subMenuClick('dashboard')">
                  <span class="text-style">
                    Dashboard
                  </span>
                  <mat-icon mat-list-icon class="chevron-button">expand_more</mat-icon>
                  <!-- [routerLink]="['user-profile/quotation-history']" -->
                  <!-- <mat-icon mat-list-icon class="chevron-button">expand_more</mat-icon> -->
              </span>
          </mat-list-item>

          <!-- Dashboard Sub Menu -->
          <ng-container *ngIf="dashboardSubMenu">
            <mat-list-item (click) = "routeEvent('quotation-history')">
              <span *ngIf="isExpanded" class="sub-menu-item text-style">Quotation History</span>
            </mat-list-item>

          </ng-container>

          <!-- Shop Section -->
          <mat-list-item (click)="navAction(isExpanded , 'shop')">
            <span class="icon-holder">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M2.91666 9.16667V11.6667C2.91666 14.8094 2.91666 16.3807 3.89297 17.357C4.86928 18.3333 6.44063 18.3333 9.58332 18.3333H10.4167C13.5594 18.3333 15.1307 18.3333 16.107 17.357C17.0833 16.3807 17.0833 14.8094 17.0833 11.6667V9.16667" stroke="currentColor" stroke-width="1.5"/>
                <path d="M7.91667 1.66667H12.0833L12.6265 7.09781C12.7818 8.65168 11.5616 10 10 10C8.43839 10 7.21817 8.65168 7.37356 7.09781L7.91667 1.66667Z" stroke="currentColor" stroke-width="1.5"/>
                <path d="M2.77477 4.45944C2.92317 3.71745 2.99737 3.34645 3.14824 3.0457C3.46613 2.412 4.03814 1.94307 4.72188 1.75563C5.04637 1.66667 5.42472 1.66667 6.18141 1.66667H7.91666L7.31289 7.7044C7.18257 9.00759 6.08596 10 4.77627 10C3.16756 10 1.96101 8.52825 2.27651 6.95078L2.77477 4.45944Z" stroke="currentColor" stroke-width="1.5"/>
                <path d="M17.2252 4.45944C17.0768 3.71745 17.0026 3.34645 16.8518 3.0457C16.5339 2.412 15.9619 1.94307 15.2781 1.75563C14.9536 1.66667 14.5753 1.66667 13.8186 1.66667H12.0833L12.6871 7.7044C12.8174 9.00759 13.914 10 15.2237 10C16.8324 10 18.039 8.52825 17.7235 6.95078L17.2252 4.45944Z" stroke="currentColor" stroke-width="1.5"/>
                <path d="M7.91666 17.9167V15.4167C7.91666 14.6378 7.91666 14.2484 8.08412 13.9583C8.19384 13.7683 8.35163 13.6105 8.54166 13.5008C8.83172 13.3333 9.22114 13.3333 9.99999 13.3333C10.7788 13.3333 11.1683 13.3333 11.4583 13.5008C11.6483 13.6105 11.8061 13.7683 11.9159 13.9583C12.0833 14.2484 12.0833 14.6378 12.0833 15.4167V17.9167" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
              </svg>
            </span>
            <div *ngIf="isExpanded" class="menu-item" (click) = "routeEvent('home')">
                <span class="text-style">
                  Shop
                </span>
            </div>
          </mat-list-item>

         <!-- ChannelPartnerSection -->

          <ng-container  *ngIf="userRolesList?.includes('ChannelPartnerAdmin') || userRoleVal === 'ChannelPartnerAdmin' || userRolesList?.includes('ChannelParter AccountManager') ">

            <mat-list-item>
              <span class="icon-holder" (click)="menuExpansion(isExpanded , 'cp')">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M22 21V19C22 17.1362 20.7252 15.5701 19 15.126M15.5 3.29076C16.9659 3.88415 18 5.32131 18 7C18 8.67869 16.9659 10.1159 15.5 10.7092M17 21C17 19.1362 17 18.2044 16.6955 17.4693C16.2895 16.4892 15.5108 15.7105 14.5307 15.3045C13.7956 15 12.8638 15 11 15H8C6.13623 15 5.20435 15 4.46927 15.3045C3.48915 15.7105 2.71046 16.4892 2.30448 17.4693C2 18.2044 2 19.1362 2 21M13.5 7C13.5 9.20914 11.7091 11 9.5 11C7.29086 11 5.5 9.20914 5.5 7C5.5 4.79086 7.29086 3 9.5 3C11.7091 3 13.5 4.79086 13.5 7Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </span>
              <div *ngIf="isExpanded" class="menu-item" (click)="subMenuClick('cp')">
                  <span class="text-style">
                   My Channel
                  </span>
                  <mat-icon mat-list-icon class="chevron-button">expand_more</mat-icon>
                </div>
            </mat-list-item>
    
          
            <ng-container *ngIf="cpSubMenu">
           
              <ng-container *ngIf="isExpanded && userRolesList?.includes('ChannelPartnerAdmin') ">

              <mat-list-item (click)="routeEvent('cpMyChannelLeads')">
                <span  class="sub-menu-item text-style">My Channel Leads</span>
              </mat-list-item> 
              <mat-list-item (click)="routeEvent('cpMyChannelInviteUsers')">
                <span  class="sub-menu-item text-style">Manage Channel</span>
              </mat-list-item>
            </ng-container>
            <ng-container *ngIf="isExpanded && userRolesList?.includes('ChannelParter AccountManager')">
              <mat-list-item (click)="routeEvent('myleadsFromChannel')">
                <span  class="sub-menu-item text-style" >Assigned Leads</span>
              </mat-list-item> 

        <mat-list-item (click) = "routeEvent('paInviteUser')">

                <span *ngIf="isExpanded" class="sub-menu-item text-style">Invite Customer</span>
              </mat-list-item>
             
            </ng-container>
           
              
            </ng-container>
          </ng-container>
        
          

                

          <!-- Admin Section -->

          <!-- <ng-container *ngIf="userRoleVal === 'superadmin'"> -->
            <ng-container *ngIf="userRolesList?.includes('SuperAdmin') || userRoleVal === 'superadmin'">
            <mat-list-item>
              <span class="icon-holder" (click)="menuExpansion(isExpanded , 'sacpdmin')">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M9.75 20.75L11.223 21.5683C11.5066 21.7259 11.6484 21.8047 11.7986 21.8355C11.9315 21.8629 12.0685 21.8629 12.2015 21.8355C12.3516 21.8047 12.4934 21.7259 12.777 21.5683L14.25 20.75M5.25 18.25L3.82297 17.4572C3.52346 17.2908 3.37368 17.2076 3.26463 17.0893C3.16816 16.9846 3.09515 16.8605 3.05048 16.7253C3 16.5725 3 16.4012 3 16.0586V14.5M3 9.5V7.94145C3 7.5988 3 7.42748 3.05048 7.27468C3.09515 7.13951 3.16816 7.01543 3.26463 6.91074C3.37368 6.7924 3.52345 6.7092 3.82297 6.5428L5.25 5.75M9.75 3.25L11.223 2.43168C11.5066 2.27412 11.6484 2.19535 11.7986 2.16446C11.9315 2.13713 12.0685 2.13713 12.2015 2.16446C12.3516 2.19535 12.4934 2.27412 12.777 2.43168L14.25 3.25M18.75 5.75L20.177 6.54279C20.4766 6.7092 20.6263 6.7924 20.7354 6.91073C20.8318 7.01542 20.9049 7.13951 20.9495 7.27468C21 7.42748 21 7.5988 21 7.94145V9.5M21 14.5V16.0586C21 16.4012 21 16.5725 20.9495 16.7253C20.9049 16.8605 20.8318 16.9846 20.7354 17.0893C20.6263 17.2076 20.4766 17.2908 20.177 17.4572L18.75 18.25M9.75 10.75L12 12M12 12L14.25 10.75M12 12V14.5M3 7L5.25 8.25M18.75 8.25L21 7M12 19.5V22" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </span>
              <div *ngIf="isExpanded" class="menu-item" (click)="subMenuClick('sacpdmin')">
                  <span class="text-style">
                    Channel  Management
                  </span>
                  <mat-icon mat-list-icon class="chevron-button">expand_more</mat-icon>
                </div>
            </mat-list-item>
    
            <!-- Security Sub Menu -->
            <ng-container *ngIf="sAdmincpSubMenu">
           
            

             
              <mat-list-item (click) = "routeEvent('saddNewChannelPartner')">
                <span *ngIf="isExpanded" class="sub-menu-item text-style">Add Channel Partner</span>
              </mat-list-item>

              <mat-list-item (click) = "routeEvent('viewChannelPartners')">
                <span *ngIf="isExpanded" class="sub-menu-item text-style">View Channel Partners</span>
              </mat-list-item>

              
              <mat-list-item (click) = "routeEvent('manageAllChannels')">
                <span *ngIf="isExpanded" class="sub-menu-item text-style">Manage Channels</span>
              </mat-list-item>

            </ng-container>
          </ng-container>

          <ng-container *ngIf="userRolesList?.includes('SuperAdmin') || userRoleVal === 'superadmin'">
            <mat-list-item>
              <span class="icon-holder" (click)="menuExpansion(isExpanded , 'sadmin')">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M21 21H4.6C4.03995 21 3.75992 21 3.54601 20.891C3.35785 20.7951 3.20487 20.6422 3.10899 20.454C3 20.2401 3 19.9601 3 19.4V3M21 7L15.5657 12.4343C15.3677 12.6323 15.2687 12.7313 15.1545 12.7684C15.0541 12.8011 14.9459 12.8011 14.8455 12.7684C14.7313 12.7313 14.6323 12.6323 14.4343 12.4343L12.5657 10.5657C12.3677 10.3677 12.2687 10.2687 12.1545 10.2316C12.0541 10.1989 11.9459 10.1989 11.8455 10.2316C11.7313 10.2687 11.6323 10.3677 11.4343 10.5657L7 15M21 7H17M21 7V11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </span>
              <div *ngIf="isExpanded" class="menu-item" (click)="subMenuClick('sadmin')">
                  <span class="text-style">
                  Sales Management
                  </span>
                  <mat-icon mat-list-icon class="chevron-button">expand_more</mat-icon>
                </div>
            </mat-list-item>
    
            <!-- Security Sub Menu -->
            <ng-container *ngIf="sAdminSubMenu">
           
            

              <mat-list-item (click) = "routeEvent('sadminAccounts')">
                <span *ngIf="isExpanded" class="sub-menu-item text-style">Leads</span>


              </mat-list-item>
              <!-- <mat-list-item>
                <span *ngIf="isExpanded" class="sub-menu-item">Quotations</span>
              </mat-list-item> -->
            

              <!-- <mat-list-item>
                <span *ngIf="isExpanded" class="sub-menu-item" (click) = "routeEvent('sadminAssignRoles')">Assign Roles</span>
              </mat-list-item> -->
             

         
              <mat-list-item (click) = "routeEvent('sMyCustomers')">
                <span *ngIf="isExpanded" class="sub-menu-item text-style" >Customers</span>
              </mat-list-item>

              <mat-list-item (click) = "routeEvent('sadminMarketPlaceUser')">
                <span *ngIf="isExpanded" class="sub-menu-item text-style">Users</span>
              </mat-list-item>

              <mat-list-item (click) = "routeEvent('paInviteUser')">
                <span *ngIf="isExpanded" class="sub-menu-item text-style">Invite Customer</span>
              </mat-list-item>

             <mat-list-item (click) = "routeEvent('role-assignment')" >
                <span *ngIf="isExpanded" class="sub-menu-item text-style">User Role Management</span>
              </mat-list-item>
              
              <!-- <mat-list-item>
                <span *ngIf="isExpanded" class="sub-menu-item"(click) = "routeEvent('managae-all-admin')" >Manage Channel Partner</span>
              </mat-list-item> -->
            </ng-container>
          </ng-container>


          <!-- account-manager -->


          <ng-container *ngIf="userRolesList?.includes('PortalAdmin') || userRoleVal === 'PortalAdmin' || userRolesList?.includes('SuperAdmin')">
            <mat-list-item>
              <span class="icon-holder" (click)="menuExpansion(isExpanded , 'admin')">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M9 7H4.6C4.03995 7 3.75992 7 3.54601 7.10899C3.35785 7.20487 3.20487 7.35785 3.10899 7.54601C3 7.75992 3 8.03995 3 8.6V19.4C3 19.9601 3 20.2401 3.10899 20.454C3.20487 20.6422 3.35785 20.7951 3.54601 20.891C3.75992 21 4.03995 21 4.6 21H9M9 21H15M9 21L9 4.6C9 4.03995 9 3.75992 9.10899 3.54601C9.20487 3.35785 9.35785 3.20487 9.54601 3.10899C9.75992 3 10.0399 3 10.6 3L13.4 3C13.9601 3 14.2401 3 14.454 3.10899C14.6422 3.20487 14.7951 3.35785 14.891 3.54601C15 3.75992 15 4.03995 15 4.6V21M15 11H19.4C19.9601 11 20.2401 11 20.454 11.109C20.6422 11.2049 20.7951 11.3578 20.891 11.546C21 11.7599 21 12.0399 21 12.6V19.4C21 19.9601 21 20.2401 20.891 20.454C20.7951 20.6422 20.6422 20.7951 20.454 20.891C20.2401 21 19.9601 21 19.4 21H15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </span>
              <div *ngIf="isExpanded" class="menu-item" (click)="subMenuClick('admin')">
                  <span class="text-style">
                    Manage Portal
                  </span>
                  <mat-icon mat-list-icon class="chevron-button">expand_more</mat-icon>
                </div>
            </mat-list-item>
    
            <!-- Security Sub Menu -->
            <ng-container *ngIf="adminSubMenu">
             
              <mat-list-item (click) = "routeEvent('paAddNewProduct')">
                <span *ngIf="isExpanded" class="sub-menu-item text-style">Add New Product</span>
              </mat-list-item>

              <mat-list-item (click) = "routeEvent('paEditProduct')" >
                <span *ngIf="isExpanded" class="sub-menu-item text-style">Edit Product</span>
              </mat-list-item>

              <mat-list-item (click) = "routeEvent('paUploadProductPrice')" >
                <span *ngIf="isExpanded" class="sub-menu-item text-style">Upload Product Price</span>
              </mat-list-item>

              <mat-list-item (click) = "routeEvent('paInviteUser')" >
                <span *ngIf="isExpanded" class="sub-menu-item text-style">Invite Customer</span>
              </mat-list-item>
              
              <mat-list-item (click) = "routeEvent('paUploadBlog')" >
                <span *ngIf="isExpanded" class="sub-menu-item text-style">Update Blog</span>
              </mat-list-item>

              <mat-list-item (click) = "routeEvent('paCreateProductOffer')" >
                <span *ngIf="isExpanded" class="sub-menu-item text-style">Create Product Offer</span>
              </mat-list-item>

              <mat-list-item (click) = "routeEvent('UserReviewList')" >
                <span *ngIf="isExpanded" class="sub-menu-item text-style">Review List</span>
              </mat-list-item>
            </ng-container>
          </ng-container>
          
          <!-- <ng-container *ngIf="userRoleVal === 'admin' || userRoleVal === 'superadmin'">
            <mat-list-item>
              <span class="icon-holder" >
                <img src="../../../../assets/icons/illustration_icons/AdminIcon.svg">
              </span>
              <div *ngIf="isExpanded" class="menu-item" >
                  <span class="text-style">
                    My Channel Data
                  </span>
                  <mat-icon mat-list-icon class="chevron-button">expand_more</mat-icon>
                </div>
            </mat-list-item>
          </ng-container> -->
        

          <ng-container *ngIf="userRolesList?.includes('PortalAdmin') || userRoleVal === 'PortalAdmin' || userRolesList?.includes('SuperAdmin')">
            <mat-list-item>
              <span class="icon-holder" (click)="menuExpansion(isExpanded , 'admin')">
                <img src="../../../../assets/icons/ManagePortal.svg">
              </span>
              <div *ngIf="isExpanded" class="menu-item" (click)="subMenuClick('fu')">
                  <span class="text-style">
                    Finance User
                  </span>
                  <mat-icon mat-list-icon class="chevron-button">expand_more</mat-icon>
                </div>
            </mat-list-item>
    
            <!-- Security Sub Menu -->
            <ng-container *ngIf="fuSubMenu">
             
              <mat-list-item>
                <span *ngIf="isExpanded" class="sub-menu-item" (click) = "routeEvent('fuLeads')" >Leads</span>
              </mat-list-item>
              <mat-list-item>
                <span *ngIf="isExpanded" class="sub-menu-item" (click) = "routeEvent('home')" >  Shop</span>
              </mat-list-item>
           

        
            </ng-container>
          </ng-container>



          <!-- ION View Options -->
          <ng-container *ngIf="userRolesList?.includes('PortalAdmin') || userRoleVal === 'PortalAdmin' || userRolesList?.includes('SuperAdmin')">
            <mat-list-item>
              <span class="icon-holder" (click)="menuExpansion(isExpanded , 'ion')">
                <img src="../../../../assets/icons/ManagePortal.svg">
              </span>
              <div *ngIf="isExpanded" class="menu-item" (click)="subMenuClick('ion')">
                  <span class="text-style">
                    Ion View
                  </span>
                  <mat-icon mat-list-icon class="chevron-button">expand_more</mat-icon>
                </div>
            </mat-list-item>
    
            <!-- Security Sub Menu -->
            <ng-container *ngIf="ionSubMenu">
             
              <mat-list-item>
                <span *ngIf="isExpanded" class="sub-menu-item" (click) = "routeEvent('ionOrders')" >Orders</span>
              </mat-list-item>
           

        
            </ng-container>
          </ng-container>
        

        </mat-nav-list>

      </div>
      <div>
        
      </div>
      </mat-sidenav>

    











    <!-- Content - rendered Content for the current router path into the <router-outlet>, e.g. /dashboard or /info-->
    <mat-sidenav-content>
      <div class="router-outlet-content">
        <router-outlet></router-outlet>
      </div>
      
    </mat-sidenav-content>
  
  </mat-sidenav-container>

</div>
