import { Component } from '@angular/core';

@Component({
  selector: 'recently-searched',
  templateUrl: './recently-searched.component.html',
  styleUrls: ['./recently-searched.component.css']
})
export class RecentlySearchedComponent {

}
