<div class="reason-container">
    <h3 class="reason-headline">Reason To Cancel</h3>
    <form>
        <div class="message-text-style">
            <span class="p-float-label">
              <textarea 
                id="messageText" 
                formControlName="messageText"
                pInputTextarea></textarea>
              <label for="messageText">Message</label>
            </span>
          </div>
    </form>

    <div class="button-section">
        <button class="btn secondary-btn" (click)="closeModal()">Cancel</button>
        <button class="btn primary-btn">Submit</button>
    </div>

</div>