<div class="bundle-card-container">
    <div class=" card-section">
        <div class="logo-container">
            <img class="logo" src="{{product.bannerLogo ? product.bannerLogo : alternateLogo}}">

        </div>
        <div class="content-section">
            <div class="headline-section">
                {{product.name}}
            </div>
            <div class="solution-category-section">
                <!-- <div class="solution-label">
                    Solution Category:
                </div> -->
                <div class="solution-category-name">
                    {{product.solutionCategory}}
                </div>
            </div>
        </div>
    </div>
</div>