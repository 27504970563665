<!-- <div class="contact-us-wrapper">
    <div class="card-container">
        <div class="modal-logo">
            <img class="company-logo" src="../../../../assets/Logos/skysecureLogo.png">
          </div>
        <search-for-compare-product (selectedItem) = "selectedItem($event)"></search-for-compare-product>
        <div class="message">
            <p>Unlock Better Choices: Compare Products Today!</p>
        </div>
        
    </div>
   
</div> -->

<div class="contact-us-wrapper">
    <div class="card-container">
        <div class="modal-header">
            <!-- <span class="header-text">Unlock Better Choices: Compare Products Today!</span> -->
            <span class="header-text">{{headerText}}</span>
            
            <button (click) = "closeModal()" type="button" class="btn-close" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <search-for-compare-product (selectedItem) = "selectedItem($event)"></search-for-compare-product>
        </div>
    </div>
</div>