
<div class="modal-container">
  <ng-container *ngIf="!showPrivacyContent">
  <div class="modal-header">
    <div class="modal-top">
      <h4>Business Details</h4>
    </div>
      <div class="close-btn-section">
       
            <img (click)="closeModal()" class="close-icon" src="../../../../assets/icons/closecircle.svg">
        
    </div>
      <!-- <div class="close-icon">
        <button (click)="closeModal()" type="button" class="btn-close" aria-label="Close"></button>
      </div> -->
   

  </div>


  <p>Please fill the mentioned details to generate estimation</p>
  <div class="modal-body">
    <div id="customerToogle">
      <!-- <p>
            <mat-button-toggle-group #group="matButtonToggleGroup" name="fontStyle" [value]="selectedType" (change)="onToogleChange(group.value)" aria-label="Font Style">
              <mat-button-toggle  class="self" value="self">Self</mat-button-toggle>
              <mat-button-toggle class="other" value="others">Others</mat-button-toggle>
            </mat-button-toggle-group>
          </p> -->
      <!-- <div class="inputGroup">
              <input id="radio1" name="radio" type="radio" />
              <label for="radio1">Male</label>
          </div>
          <div class="inputGroup">
              <input id="radio2" name="radio" type="radio" />
              <label for="radio2">Female</label>
          </div> -->

      <div class="radio-group">
        <input type="radio" [checked]="isChecked" name="myRadios" (change)="handleChange('self')" value="self" />
        <span>Self</span>
      </div>
      <div class="radio-group">
        <input type="radio" [checked]="!isChecked" name="myRadios" (change)="handleChange('others')" value="others" />
        <span>Others</span>
      </div>


    </div>

    <!-- <div id="#radio-button-group">
            <div class="flex flex-wrap gap-3">
                <div class="flex align-items-center">
                    <p-radioButton name="radioButton" value="gst" (click)=onRadioButtonClick() [(ngModel)]="radioValue" inputId="gst"></p-radioButton>
                    <label for="gst" class="ml-2">GST</label>
                </div>
                
                <div class="flex align-items-center">
                    <p-radioButton name="radioButton" value="manual" (click)=onRadioButtonClick() [(ngModel)]="radioValue" inputId="manual"></p-radioButton>
                    <label for="manual" class="ml-2">Manual</label>
                </div>
            </div>
        </div> -->
        
  
<div *ngIf="!showContent" class="cont">
    <div class="container mt-3">
      <form [formGroup]="myForm">
        

        <div class="row jumbotron box8">



          <div class="company-gst">
            <form [formGroup]="myForm">


              <ng-container *ngIf="selectedType !== 'others'">

                  <p>Please Enter Company GST Number <a class="find-gst" href="https://www.knowyourgst.com/gst-number-search/by-name-pan/" target="_blank">(Search GSTIN)</a></p>


                  <div class="col-sm-11 form-group">
                    <input formControlName="gstNo" type="text" class="form-control " name="gstNo" id="gst-no"
                      placeholder="Enter your GST NO" >
                    
                      {{disableGstNOField()}}
                      <span class="text-danger" *ngIf="myForm.get('gstNo').invalid">Please enter valid GST Number</span>
                  </div>
                  <p class="or ">(Or)</p>
                  <div class="col-sm-12">
                    <input type="checkbox" formControlName="checkGstNil" class="form-check d-inline" id="chb"  >
                    <div for="chb" class="form-check-label" >&nbsp;I Don't Have Company GST Number.
                      
                      {{disableCheckGstNil()}}
                    </div>
                  </div>
                  <div *ngIf="selectedType === 'others'" class="">
                    
                    <div class="form-check-label bottom-border">&nbsp;
                    </div>
                  </div>

              </ng-container>

              <ng-container *ngIf="othersGSTShow">

                <p>Please Enter Company GST Number <a class="find-gst" href="https://www.knowyourgst.com/gst-number-search/by-name-pan/" target="_blank">(Search GSTIN)</a></p>


                <div class="col-sm-11 form-group">
                  <input formControlName="gstNo" type="text" class="form-control " name="gstNo" id="gst-no"
                    placeholder="Enter your GST NO" >
                  
                    {{disableGstNOField()}}
                    <span class="text-danger" *ngIf="myForm.get('gstNo').invalid">Please enter valid GST Number</span>
                </div>
                <p class="or ">(Or)</p>
                <div class="col-sm-12">
                  <input type="checkbox" formControlName="checkGstNil" class="form-check d-inline" id="chb"  >
                  <div for="chb" class="form-check-label" >&nbsp;I Don't Have Company GST Number.
                    
                    {{disableCheckGstNil()}}
                  </div>
                </div>
                <div *ngIf="selectedType === 'others'" class="">
                  
                  <div class="form-check-label bottom-border">&nbsp;
                  </div>
                </div>

            </ng-container>


              <div *ngIf="selectedType === 'others' && showWithoutGST" class="">
                
                <div class="form-check-label" style="margin-top:10px">&nbsp; *Please fill the contact details
                </div>
              </div>

            </form>

            <!-- <div class="modal-footer" >
                  <button class="btn compare-btn">Next</button>
                </div> -->


          </div>





          <ng-container *ngIf="selectedType === 'others' && showWithoutGST">
            <div class="name-row-01">
              <div class="col form-group">
                <label for="firstName">Name*</label>
                <input formControlName="firstName" type="firstName" class="form-control-01" name="Locality" (input)="onInputChanges($event)"
                  id="firstName" placeholder="Name" required>
                  
              </div>
              <div class="col form-group">
                <label for="email">Email ID*</label>
                <input formControlName="email" type="email" class="form-control-01" name="email" id="email" (input)="onInputChanges($event)"
                  placeholder="Email ID" required>
                  <div class="error-message" *ngIf="otherNextClickFlag && myForm.get('email').hasError('required')">Email ID is required</div>
                  <div class="error-message" *ngIf="otherNextClickFlag && myForm.get('email').hasError('email')">Please enter valid email address</div>
              </div>
            </div>
            <div class="col-sm-9 form-group">
              <label for="cod">Country code</label>
              <select class="form-control browser-default custom-select">
                <option data-countryCode="IN"  value="1" selected>IN (+91)</option>
                <option data-countryCode="US" value="2" >USA (+1)</option>
                <option data-countryCode="GB" value="44">UK (+44)</option>
              </select>
            </div>
            <div class="col-sm-8 form-group">
              <label for="tel">Phone</label>
              <input formControlName="phoneNo" type="tel" name="phone" class="form-control-02" id="tel" (input)="onInputChanges($event)"
                placeholder="Enter Your Phone No." >
            </div>
          </ng-container>

          
          <div class="col-sm-11 form-group" *ngIf="selectedType ==='hide'">
            <label for="gst-no">GST Number</label>
            <input formControlName="gstNo" type="text" class="form-control " name="gstNo" id="gst-no"
              placeholder="Enter your GST NO" required>
          </div>
          
          <div class="name-row" *ngIf="selectedType === 'hide'">
            <div class="col-sm form-group">
              <label for="companyName">Company Name*</label>
              <input formControlName="companyName" type="companyName" class="form-control-01" name="companyName"
                id="companyName" placeholder="Company Name" required>
            </div>
            <div class="col-sm-2 form-group" *ngIf="selectedType === 'self'">
              <label for="cod">Country code</label>
              <select class="form-control browser-default custom-select">
                <option data-countryCode="IN" value="44">IN (+91)</option>
                <option data-countryCode="US" value="1">USA (+1)</option>
                <option data-countryCode="GB" value="44">UK (+44)</option>


              </select>
            </div>
            <div class="col-sm-4 form-group" *ngIf="selectedType === 'hide'">
              <label for="tel">Phone</label>
              <input formControlName="phoneNo" type="tel" name="phone" class="form-control-02" id="tel"
                placeholder="Enter Your Phone No." required>
              <div *ngIf="myForm.get('phoneNumber').hasError('required')">
                Phone number is required.
              </div>
              <div *ngIf="myForm.get('phoneNumber').hasError('pattern')">
                Phone number must be 10 digits.
              </div>
            </div>
          </div>
         

          <div class="address-row" *ngIf="selectedType === 'hide'">
            <div class="col-sm-6 form-group">
              <label for="address-1">Address Line-1</label>
              <input formControlName="addressLine1" type="address" class="form-control-01" name="Locality"
                id="address-1" placeholder="Locality/House/Street no." >
            </div>
            <div class="col-sm-6 form-group">
              <label for="address-2">Address Line-2*</label>
              <input formControlName="addressLine2" type="address" class="form-control-01" name="address" id="address-2"
                placeholder="Village/City Name." required>
            </div>
            <div class="col-sm-6 form-group">
              <label for="Country">Country*</label>
              <select formControlName="countryName" class="form-control" (change)="onCountryChange($event)">
                <option disabled value="'Select Country'">Select Country </option>
                <option *ngFor="let item of countryList" [value]="item | json">{{item.name}}</option>
              </select>
              


              
        
      
            </div>
          </div>


          <div class="address-row" *ngIf="selectedType === 'hide'">
            <div class="col-sm-6 form-group">
              <label for="State">State*</label>
              <select formControlName="stateName" class="form-control" (change)="onStateChange($event)">
                <option disabled value="'Select State'">Select Country </option>
                <option *ngFor="let item of stateList" [value]="item | json">{{item.name}}</option>
              </select>
            </div>
            <!-- <div class="col-sm-6 form-group">
                    <label for="State">State</label>
                    <select class="form-control" [(ngModel)] ="selectedState" (change)="onStateChange($event, selectedState)">
                      <option disabled value="'Select State'">Select Country </option>
                      <option *ngFor="let item of stateList" [ngValue]="item">{{item.name}}</option>
                  </select>
                </div> -->

            <div class="col-sm-6 form-group">
              <label for="State">City*</label>
              <select formControlName="cityName" class="form-control" (change)="onCityChange($event)">
                <option disabled value="'Select City'">Select City </option>
                <option *ngFor="let item of cityList" [value]="item | json">{{item.name}}</option>

              </select>
            </div>
            <div class="col-sm-6 form-group">
              <label for="zip">Postal-Code*</label>
              <input formControlName="postalCode" type="zip" class="form-control" name="Zip" id="zip"
                placeholder="Postal-Code." required>
            </div>
          </div>
          
           

         

          <!-- <div class="col-sm-2 form-group">
                  <label for="cod">Country code</label>
                  <select class="form-control browser-default custom-select">
                    <option data-countryCode="US" value="1" selected>USA (+1)</option>
                    <option data-countryCode="GB" value="44">UK (+44)</option>
          
                    
                  </select>
                </div> -->


          <!-- <div class="col-sm-12" *ngIf="selectedType === 'others'">
            <input type="checkbox" class="form-check d-inline" id="chb" required>
            <div for="chb" class="form-check-label">&nbsp;I accept all terms and conditions.
            </div>
          </div> -->

        </div>
      </form>
    </div>







  </div>
  </div>
  <!-- <div class="modal-footer">
        <button class="btn compare-btn" (click)="createQuotationService2()">Submit</button>
    </div> -->
 
 
    <div *ngIf="errorMessage">
      <p class="error">{{errorMessageText}}</p>
      <!-- <p class="error">* Please choose one option: Enter a Company GST number or select the I don't have Company GST number checkbox</p>
      {{disableAnyOneMessage()}} -->
    </div>




    <ng-container *ngIf="!showContent == showButton">
      
  <!-- <button class="btn compare-btn" (click)="showContent = !showContent">Next</button> -->
  
  <div class="modal-footer">

    <ng-container *ngIf="(selectedType === 'others' && showWithoutGST); else otherScenario">
      
      <button class="btn secondary-btn"  (click)="onBackGSTDetails()">Cancel</button>
      <button class="btn primary-btn" [disabled]="buttonDisabled" (click)="onNextClick()">Next</button>
    </ng-container>
    <ng-template #otherScenario>
      <button class="btn secondary-btn"  (click)="onCancelClick()">Back</button>
      <button class="btn primary-btn" [disabled]="buttonDisabled" (click)="onNextClickForGST()">Next</button>
    </ng-template>
    
    
    
  </div>
</ng-container>

  <div *ngIf="showContent" class="cont">


    <div class="container mt-3">
      <form [formGroup]="myForm">

        <div class="row jumbotron box8">

          <ng-container *ngIf="selectedType === 'others'">
            <div class="address-row" *ngIf="selectedType === 'others'">
              <div class="col-sm-6 form-group">
                <label for="address-1">Address Line-1</label>
                <input formControlName="addressLine1" type="address" class="form-control-01" name="Locality"
                  id="address-1" placeholder="Locality/House/Street no." >
              </div>
              <div class="col-sm-6 form-group">
                <label for="address-2">Address Line-2*</label>
                <input formControlName="addressLine2" type="address" class="form-control-01" name="address"
                  id="address-2" placeholder="Village/City Name." required>
              </div>
              <div class="col-sm-6 form-group">
                <label for="Country">Country*</label>
                <!-- <select formControlName="countryName" class="form-control" (change)="onCountryChange($event)">
                  <option disabled value="'Select Country'">Select Country </option>
                  <option *ngFor="let item of countryList" [value]="item | json">{{item.name}}</option>
                </select> -->
                <select formControlName="countryName" class="form-control" (change)="onCountryChange($event)" >
                  <ng-container *ngIf="gstData; else noGstData">
                    
                    <option value="India">India</option>
                  </ng-container>
                  <ng-template #noGstData>
                    <option disabled value="'Select Country'">Select Country </option>
                    <option *ngFor="let item of countryList" [value]="item | json">{{item.name}}</option>
                  </ng-template>
                  
                </select>

              </div>
            </div>

            <div class="address-row" >
              <div class="col-sm-6 form-group">
                <label for="State">State*</label>
                <!-- <select formControlName="stateName" class="form-control" (change)="onStateChange($event)">
                  <option disabled value="'Select State'">Select Country </option>
                  <option *ngFor="let item of stateList" [value]="item | json">{{item.name}}</option>
                </select> -->
                <select formControlName="stateName" class="form-control" (change)="onStateChange($event)">
                  <ng-container *ngIf="gstData; else noGstDataState">
                    
                    <option value="">{{gstResponseData.adress.state}}</option>
                  </ng-container>
                  <ng-template #noGstDataState>
                    <option disabled value="'Select State'">Select State</option>
                    <option *ngFor="let item of stateList" [value]="item | json">{{item.name}}</option>
                  </ng-template>
                </select>
              </div>
  
  
              <div class="col-sm-6 form-group">
                <label for="State">City*</label>
                <!-- <select formControlName="cityName" class="form-control" (change)="onCityChange($event)">
                  <option disabled value="'Select City'">Select City </option>
                  <option *ngFor="let item of cityList" [value]="item | json">{{item.name}}</option>
  
                </select> -->
                <select formControlName="cityName" class="form-control" (change)="onCityChange($event)">
                  <ng-container *ngIf="gstData; else noGstDataCity">
                    
                    <option value="">{{gstResponseData.adress.city}}</option>
                  </ng-container>
                  <ng-template #noGstDataCity>
                    <option disabled value="'Select City'">Select City</option>
                    <option *ngFor="let item of cityList" [value]="item | json">{{item.name}}</option>
                  </ng-template>
                </select>
              </div>
              <div class="col-sm-6 form-group">
                <label for="zip">Postal-Code*</label>
                <input formControlName="postalCode" type="zip" class="form-control" name="Zip" id="zip"
                  placeholder="Postal-Code." required>
              </div>
            </div>
          </ng-container>

          <div class="col-sm-11 form-group" *ngIf="selectedType ==='hide'">
            <label for="gst-no">GST Number</label>
            <input formControlName="gstNo" type="text" class="form-control " name="gstNo" id="gst-no"
              placeholder="Enter your GST NO" required>
          </div>


          <div class="name-row" >
            <div class="col-sm form-group">
              <label for="companyName">Company Name*</label>
              <input formControlName="companyName" type="companyName" class="form-control-01" name="companyName"
                id="companyName" placeholder="Company Name" required>
            </div>
            <div class="col-sm-2 form-group" *ngIf="selectedType === 'self'">
              <label for="cod">Country code</label>
              <select class="form-control browser-default custom-select">
                <option data-countryCode="IN" value="44">IN (+91)</option>
                <option data-countryCode="US" value="1">USA (+1)</option>
                <option data-countryCode="GB" value="44">UK (+44)</option>


              </select>
            </div>
            <div class="col-sm-4 form-group" *ngIf="selectedType === 'self'">
              <label for="tel">Phone*</label>
              <input formControlName="phoneNo" type="tel" name="phone" class="form-control-02" id="tel"
                placeholder="Enter Your Phone No." required>
              <!-- <div *ngIf="myForm.get('phoneNumber').hasError('required')">
                Phone number is required.
              </div>
              <div *ngIf="myForm.get('phoneNumber').hasError('pattern')">
                Phone number must be 10 digits.
              </div> -->
            </div>
          </div>

          <div class="address-row" *ngIf="selectedType === 'self'">
            <div class="col-sm-6 form-group">
              <label for="address-1">Address Line-1</label>
              <input formControlName="addressLine1" type="address" class="form-control-01" name="Locality"
                id="address-1" placeholder="Locality/House/Street no." >
            </div>
            <div class="col-sm-6 form-group">
              <label for="address-2">Address Line-2*</label>
              <input formControlName="addressLine2" type="address" class="form-control-01" name="address" id="address-2"
                placeholder="Village/City Name." required>
            </div>
            <div class="col-sm-6 form-group">
              <label for="Country">Country*</label>
              <select formControlName="countryName" class="form-control" (change)="onCountryChange($event)" >
                <ng-container *ngIf="gstData; else noGstData">
                  
                  <option value="India">India</option>
                </ng-container>
                <ng-template #noGstData>
                  <option disabled value="'Select Country'">Select Country </option>
                  <option *ngFor="let item of countryList" [value]="item | json">{{item.name}}</option>
                </ng-template>
                
              </select>
              <!-- <select formControlName="countryName" class="form-control" id="exampleFormControlSelect1" [(ngModel)]="selectedValue">
                    <option *ngFor="let quantity of Quantities" [ngValue]="quantity">{{quantity}}</option>
              </select> -->


              <!-- <select formControlName="countryName" class="form-control" (change)="onCountryChange($event)" id="exampleFormControlSelect1" [(ngModel)]="selectedValue.isoCode">
                <option *ngFor="let item of countryList" [ngValue]="item.isoCode">{{item.name}}</option>
              </select> -->

            </div>
          </div>


          <div class="address-row" *ngIf="selectedType === 'self'">
            <div class="col-sm-6 form-group">
              <label for="State">State*</label>
              <!-- <select formControlName="stateName" class="form-control" (change)="onStateChange($event)">
                <option disabled value="'Select State'">Select State </option>
                <option *ngFor="let item of stateList" [value]="item | json">{{item.name}}</option>
              </select> -->
              <select formControlName="stateName" class="form-control" (change)="onStateChange($event)">
                <ng-container *ngIf="gstData; else noGstDataState">
                  
                  <option value="">{{gstResponseData.adress.state}}</option>
                </ng-container>
                <ng-template #noGstDataState>
                  <option disabled value="'Select State'">Select State</option>
                  <option *ngFor="let item of stateList" [value]="item | json">{{item.name}}</option>
                </ng-template>
              </select>
              
            </div>


            <div class="col-sm-6 form-group">
              <label for="State">City*</label>
              <!-- <select formControlName="cityName" class="form-control" (change)="onCityChange($event)">
                <option disabled value="'Select City'">Select City </option>
                <option *ngFor="let item of cityList" [value]="item | json">{{item.name}}</option>

              </select> -->
              <select formControlName="cityName" class="form-control" (change)="onCityChange($event)">
                <ng-container *ngIf="gstData; else noGstDataCity">
                  
                  <option value="">{{gstResponseData.adress.city}}</option>
                </ng-container>
                <ng-template #noGstDataCity>
                  <option disabled value="'Select City'">Select City</option>
                  <option *ngFor="let item of cityList" [value]="item | json">{{item.name}}</option>
                </ng-template>
              </select>
            </div>
            <div class="col-sm-6 form-group">
              <label for="zip">Postal-Code*</label>
              <input formControlName="postalCode" type="zip" class="form-control" name="Zip" id="zip"
                placeholder="Postal-Code." required>
            </div>
          </div>


         
          
          <div class="col-sm-6" *ngIf="selectedType === 'self' " >
            <label for="State">Refered By*</label>
            <select formControlName="referedName" class="form-control" (change)="onChannelParterChange($event)">
              <option disabled value="'Select '">Select </option>
              <option *ngFor="let item of channelPartnerList" [value]="item | json">{{item.channelPartnerMaster.companyBusinessName}}</option>
           

            </select>
          </div> 

          <div class="col-sm-6" *ngIf="selectedType === 'others' " >
            <label for="State">Refered By*</label>
            <select formControlName="referedName" class="form-control" (change)="onChannelParterChange($event)">
              <option disabled value="'Select '">Select </option>
              <option *ngFor="let item of channelPartnerList" [value]="item | json">{{item.channelPartnerMaster.companyBusinessName}}</option>

            </select>
          </div>
<!-- 
          <div class="col-sm-12">
       
            <input>
          
          
          </div> -->


          <div class="col-sm-12">
       
            <input type="checkbox" formControlName="checkTerms"  class="form-check d-inline" id="chb" required>
            {{disableCheckTerms()}}
            <div for="chb" class="form-check-label">&nbsp;I accept all <span class="condition"  (click)="onTermsClick()"> T&C.</span>
            {{disableErrorMessage()}}
            </div>
          </div>

   
        <div *ngIf="submitErrorMessage">
          <p class="error">{{submitErrorMessageText}}</p>
        </div>
        <div *ngIf="submitCityError">
          <p class="error">*Please Fill Mandaotory fields</p>
        </div>

          <div class="modal-footer">
            <button class="btn secondary-btn"  (click)="onBackClick()">Back</button>
            <ng-container >
              <button class="btn primary-btn" (click)="createQuotationService()">Submit</button>
          </ng-container>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-container>

<div *ngIf="showPrivacyContent" class="privacy">
  <div class="header">
    <h2>Terms  And Conditions</h2>
  </div>
  <div class="privacy-cont">
    
      <ol>
        <li>Buyer shall mean the person/entity, who accepts/acknowledges these Terms with SKYSECURE TECHNOLOGIES PRIVATE LIMITED, the Seller. 
        </li>
        <li> Cloud Services shall mean Cloud Software as a Service (SaaS), Cloud Platform as a Service (PaaS) and Cloud Infrastructure as a Service (IaaS) as subscribed by the Buyer as the case may be, invoiced by the Seller and governed by these terms. </li>
        <li> The terms herein are the binding Contract between Seller and the Buyer and all orders placed by the Buyer with Seller shall be subject to the terms and conditions herein below. These terms shall prevail over all previous, contemporary communications exchanged between the parties, whether or not in writing including the terms and conditions of invoices under which the Cloud Services were sold to the Buyer prior to the date hereof or Buyers Purchase order terms. The Buyer shall not be entitled to vary, amend, add, or alter any of these conditions. </li>
       <li>Every invoice shall be a binding contract between the Seller and Buyer on principal dealing with principal basis and shall be deemed conclusive on acceptance of the Cloud Services by/on behalf of the Buyer. The Buyer shall not in any event be deemed or construed to be an agent, contractor or representative of the Seller. This Contract shall be in addition to the terms of Dealership Registration Form/ Dealership Registration Agreement and other specific terms of respective Cloud Service Provider as published on the Sellers website/cloud portal. </li>
       <li>Buyer shall ensure that Buyer and its customers comply with all anticorruption laws including but not limited to PCA, FCPA, UK Bribery Act, Prevention of Corruption Act or any other country specific laws as applicable. </li> 
      <li>Buyer shall be responsible for payment of GST and all other duties/taxes as applicable to the sale. If at any time before or after receipt of Cloud Services by the Buyer, any duty / tariff / tax or charge of whatsoever nature is imposed / increased by the Government of India or the State Government or any other Statutory Authority with retrospective effect, then the Buyer shall be liable to reimburse the Seller the difference in the tax to the extent of such increase in respect of the supplies made prior to such change to the extent of the new imposition or increase thereof. </li>
      <li>The contract is subject to force majeure events which includes but is not limited to Act of God, fire, flood, war, public disaster, strike, governmental enactment, rule or regulation or any other cause beyond the Sellers control. Seller shall not be liable for delay in providing Cloud Services on account of such force majeure events. The Buyer shall not be entitled for any compensation, damages, loss under any circumstances even if the Seller is advised of such possibility earlier, whether or not the time is the essence of the contract. </li>
        <li>The Buyer shall provide necessary certificates against all statutory deductions made out of the payments paid to Seller, within ninety (90) days before the end of the quarter failing which Seller shall have the legal right to raise separate invoice on the Buyer against the amount so deducted. Any amount deducted shall be the liability of the Buyer.</li>
     <li>No dispute regarding the quantum of the Cloud Services can be raised without written notice to Seller within five (5) days of this invoice. In respect of any such dispute relating to the quantum of the Cloud Services, the Buyer agrees to first make payment in full before raising such claim. </li>
     <li>All payments for the Cloud Services shall be made by the Buyer at the Sellers Registered Office at Katha No 911, Sy no 6/4, 3rd Floor, AGR Plaza, Outer Ring Road Bengaluru, Karnataka - 560103. All payments made by cheques are subject to realization and if made by electronic mode (NACH etc.) payment would be subject to receipt of fund in Seller's account. Buyer irrevocably undertakes not to hold payments due to the Seller on account of dispute between the Buyer and the Cloud Service Provider or a third party for whatever reason. Buyer shall pay interest @ 24% per annum, or the maximum interest allowed under applicable laws for payment made beyond the due date until the date of realization with the applicable taxes and penalties. </li>
     <li>   All bank charges in respect of the payment (including collection or cheque bouncing charges, return charges pursuant to dishonour of standing instructions) and stamp duty on bills of exchange, hundies wherever applicable shall be paid by the Buyer under this invoice with GST as applicable to the account of the Buyer. The Seller shall not be liable for any loss or theft of bank drafts, cheques etc. in transit. 
    </li>
    <li> Any notice or other documents shall be deemed to be validly served on the Buyer if sent by ordinary post/reputed courier to the known address of the Buyer or to the registered email address of the Buyer. 
    </li>
  <li>All and any dispute arising out of all or any terms of this Contract between the Parties herein shall be resolved through Arbitration. Either of the Parties may notify the dispute to the other party, enabling them to find an amicable settlement of the dispute within thirty (30) days of such notification. In the event if no amicable settlement is arrived within thirty (30) days as stated supra, either of the parties shall refer the dispute to Arbitration. M/s Skysecure technologies Pvt Ltd, shall immediately appoint a Sole Arbitrator within one (1) month of such reference of dispute to Arbitration. Buyer explicitly agrees and waives off any right to question the appointment of Arbitrator as above. Such proceedings shall be conducted in English language only and in accordance with the provisions of The Arbitration and Conciliation Act, 1996, as amended. The finding of the Sole Arbitrator shall be final and be binding on all the parties. The Venue will be as fixed by the Sole Arbitrator and the same will be binding on the parties. The Seat for the Arbitration shall be exclusively at Bengaluru.The governing laws shall be Indian laws and the parties explicitly agree that all or any legal proceedings in connection with the Arbitration proceedings shall be subject to the exclusive Jurisdiction of Courts in Bengaluru alone. The Seller shall also have the right to initiate the appropriate civil/criminal proceedings including complaint u/s 138 of NI Act, as applicable. 
  </li>
  <li>It is acknowledged and confirmed that the Seller has the right to transfer or assign any of its rights arising out of these terms of contract/ invoice including without limitation the right to transfer or assign the receivables to any third party, without having to secure any separate consent from the Buyer. 
  </li>
  <li>Buyer acknowledges that this is a contract for supply/fulfillment of Cloud Services and the Cloud Services so provided under these presents are being made available #AS IS# provided by the Cloud Service Provider or their respective Suppliers. Seller does not make any representation towards warranty or functionality or quality or fitness for particular purpose in respect of the Cloud Services made available hereunder. All claims in relation to the Cloud Services shall be addressed only to the Cloud Service Provider. 
  </li>
  <li> The Buyer explicitly agrees not to hold the Seller responsible and liable for quantum, quality, functionality or defect in the Cloud Services provided hereunder including intellectual property infringement claims such as patent, copyright and trademark infringement claims from the Buyer or a third party. Buyer undertakes not to use the patents, trademarks or trade names of Seller or the Cloud Service Providers without the prior written consent of the Seller. 
  </li>
  <li>Buyer shall ensure compliance of all applicable local laws relating to its business as well as other applicable foreign laws including the respective Cloud Service Provider terms in respect of the Cloud Services availed by the Buyer. Buyer shall also ensure that the Buyers customers / end users comply with the above requirement. It is the responsibility of the Buyer to ascertain respective Cloud Service Provider terms on its/their own for the purpose of compliance and shall inform their customers / end users of this requirement. Seller shall in no event be liable for an act of ignorance of applicable law by the Buyer or its customers. 
  </li>
  <li> Buyer hereby undertakes to comply with any import, re-import, export and re-export control laws or regulations that are applicable to Cloud Services and shall not under any circumstances, use, sell or cause to be sold through any third party, the Cloud Services for use in connection with chemical, biological, nuclear weapons, mass destructive or in unauthorized applications or in a facility engaged in such activities, within or outside India and to customers who are prevented from receiving the Cloud Services under any laws including U.S export regulations. The Buyer would also ensure that the Buyers customers are kept fully informed of all such compliance requirements and make all efforts to ensure that the customers adhere to all the compliance requirements. 
  </li>
  <li>Seller shall not be responsible for any wrong/misrepresentation made by the Buyer in respect of the Cloud Services. The total cumulative liability of the Seller to Buyer, its customers or to any third party under any circumstances shall not exceed the amount of the particular invoice giving rise to such claim. Seller shall not be liable for any direct, indirect, special or consequential damages even if advised of such possibilities earlier. 
  </li>
  <li>The buyer understands that seller does not guarantee that the cloud services provided will be immune to cyber attacks, data breaches, or security incidents. Cybersecurity threats are dynamic and evolving, and no system can be completely immune to such risks. Any such incidents should be taken directly with cloud service provider. The buyer is responsible for adhering to cybersecurity best practices, including but not limited to implementing strong access controls, regularly updating passwords, encrypting sensitive data, and educating their personnel about security awareness.The buyer acknowledges and agrees that Seller, including its officers, employees, and agents, shall not be held responsible or liable for any damages, losses, or expenses incurred as a result of cyber attacks or security breaches that may occur within the cloud services provided by Cloud Service Provider. The buyer agrees to indemnify and hold harmless Skysecure Technologies Pvt Ltd from any claims, damages, liabilities, losses, costs, or expenses arising from or related to any cyber attacks or security breaches affecting the buyer's data or applications within the cloud infrastructure of the third-party service provider. Seller makes no warranties, express or implied, regarding the security or reliability of the Cloud Service Provider's services. All services are provided "as is" and "as available."
  </li>
  <li> The Buyer acknowledges and agrees that they have a limited period of three (3) calendar days from the date of placing an Order to initiate a request for termination of said Order. The Termination Period shall be calculated as commencing from the date of the Order. If the Buyer wishes to terminate the Order within the Termination Period, they must provide written notice of the termination request to the Seller via certified mail, email, or other written means that provide a verifiable record of receipt. The notice must clearly state the Buyer's intent to terminate the Order and reference the specific Order in question.If the Buyer fails to submit a written termination request within the Termination Period, the Order shall be deemed non-cancelable, and the Buyer shall remain liable for the full payment of all amounts specified in the Order, including any applicable taxes, fees, and shipping costs. The Seller reserves the right to accept or reject any termination request made by the Buyer within the Termination Period at its sole discretion. The Seller shall communicate its decision to the Buyer in writing within a reasonable time after receiving the termination request. Any refund shall be processed in accordance with the Seller's refund policy and in conjunction with Cloud Service Providers service cancelation policy, in effect at the time of termination
  </li>
  <li>The Buyer acknowledges and agrees that, as a condition of placing an Order, they are required to submit a valid purchase order ("PO"). The PO must accurately reflect the details of the Order, including but not limited to product quantities, descriptions, pricing, delivery dates, and shipping information. The Seller shall review the PO submitted by the Buyer to ensure its alignment with the terms and specifications of the corresponding Order. If the PO submitted by the Buyer does not match the Order, the Seller shall promptly notify the Buyer of the discrepancies and provide an opportunity for the Buyer to rectify or resubmit a corrected PO. If the discrepancies between the PO and the Order are deemed irreparable or if the Buyer fails to submit a corrected PO within a reasonable time frame as determined by the Seller, the Seller shall have the right to cancel the Order associated with the mismatched PO without further obligation. In the event of an Order cancellation due to a mismatched or unrepaired PO, the Buyer acknowledges and agrees to be liable for any usage, services, or products received or consumed up to the date of the Order cancellation. The Buyer shall promptly pay the Seller for the corresponding usage or products as invoiced by the Seller </li>
    </ol>
 
  </div>


  <div class="accept">
    <div class="accept-decline">
      <input type="checkbox"  class="form-check d-inline" id="chb" [(ngModel)]="isChecked1" (change)="checkboxChanged(1)">
      <div for="chb" class="label">&nbsp;Accept
      </div>
    </div>

    <div class="accept-decline">
      <input type="checkbox"  class="form-check d-inline" id="chb" [(ngModel)]="isChecked2" (change)="checkboxChanged(2)">
      <div for="chb" class="label">&nbsp;Decline
      </div>
    </div>
  </div>
 
  <!-- <div class="modal-footer">
    <button class="btn secondary-btn"  (click)="onPreviousClick()">Next</button>
  
  </div> -->
  

</div>


<ngx-spinner
bdColor="rgba(51,51,51,0.8)"
size="medium"
color="#fff"
type="ball-scale-multiple"
>
<p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>


</div>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
