


<div class="navbar">



  <div class="logo-section" (click)="goToHomePage()">
    <img class="realize-logo" alt=""
    src="../../../assets/Logos/skysecureLogo.png" 
    />
  </div>


  <!-- <div *ngIf="(globalSearchBarVisibility$ | async)"> -->
  <div *ngIf="searchBarVisibility">
    <header-serach-bar></header-serach-bar>
  </div>

  

  <div class="icons-section">

    <!-- <div>
      <button class="searchicon-btn" *ngIf="!selected" (click)="openSearch(selected)">
        <img alt="search-icon" class="search-icon" src="../../../assets/icons/Search-1.svg">
      </button>
    
      <ng-container *ngIf="selected">
        <header-serach-bar></header-serach-bar>
      </ng-container>
    
      
      
    </div> -->

    <ng-container *ngIf="userLoggedIn">
    <div class="notificationicon-section"  matTooltip="Notification" matTooltipPosition="below" (click)="openNotificationDropdown()">

      
      <div class="notification_icon">
        <img class="notification-icon" alt="" src="../../../assets/icons/BellWhite.svg">
        <ng-container *ngIf="(notificationList$ | async) as notifications">
          <ng-container *ngIf=" notificationList.length > 0">
  
            <span class="notification__badge">{{notificationList.length}}</span>
          </ng-container>
        </ng-container>
      </div>
        
    


      
    </div>
  </ng-container>

  <ng-container *ngIf="notificationDropDown">
    <div class="notification-dropdown">
      <ng-container *ngIf="notificationList && notificationList.length>0">
        <app-notification-dropdown [notificationList] = "notificationList"></app-notification-dropdown>
      </ng-container>
      
    </div>
  </ng-container>


<ng-container *ngIf="userLoggedIn">
  <div class="cart-section">
    <button class="cart-button" matTooltip="Cart" matTooltipPosition="below" [matBadge]="cartItemCounts" (click)="cartFynction()">
      <div class="cartposition">
        <img alt="cart" class="cart-icon" src="../../../assets/icons/CartWhite.svg">
        <!-- <span>Cart</span> -->

        <ng-container *ngIf="(cartItems$ | async) as cartItems">
          <ng-container *ngIf=" cartItems.length > 0">

            <span class="icon-button__badge">{{numberOfCartItems}}</span>
          </ng-container>
        </ng-container>
      </div>
    </button>
  </div>
</ng-container>
<!-- <ng-container *ngIf="!userLoggedIn">

</ng-container> -->
    





    
    <div class="user-icon"  *ngIf="!userLoggedIn" (click)="navigateToLogin()">
      <img class="usericon" alt="" src="../../../assets/icons/User_icon.svg"/>
    </div>



    <div class="user-icon"  *ngIf="userLoggedIn">
      <div mat-button [matMenuTriggerFor]="afterMenu" class="user-header">
        <!-- <img class="usericon" alt="" src="../../../assets/icons/User_icon.svg"/>  -->
        <span class="username"> Welcome {{userFullName}}</span>
        <i class="fa fa-caret-down icon-color" aria-hidden="true"></i>

      </div>
     

        <mat-menu #afterMenu="matMenu" yPosition="below"  >
        <!-- <div class="user-profile " > -->
         
          <button  mat-menu-item  class="userProfile"[routerLink]="['user-profile/profile-view']">   <img src="../../../assets/icons/userProfile.svg" alt="" class="profile"> Update Profile</button>
        <!-- </div> -->
          <div class="log-btn">
          <!-- <button class="userName">
            <img class="usersection-icon" src="../../../assets/icons/DefaultUserIcon.svg">
            {{userFullName}}</button> -->
          <!-- user profile is commented for now need to uncomment after the functionality completed -->
         
          <button  mat-menu-item (click)="logout()">
            <img class="usersection-icon" src="../../../assets/icons/LogoutIcon.svg">Logout</button>
          </div>
          </mat-menu>
      
     
      
    </div>
    <div>
      <!-- <button mat-button [matMenuTriggerFor]="afterMenu">
        <span class="text-normal">OFFER</span>
        <i class="fa fa-caret-down" aria-hidden="true"></i>
      </button>
        <mat-menu #afterMenu="matMenu" yPosition="below">
        <button mat-menu-item>Item 1</button>
        <button mat-menu-item>Item 2</button>
        </mat-menu> -->
    </div>


    <!-- <input type="checkbox" id="check">
    <label for="check" class="checkbtn">                        
      <i class="user-img" class="fa fa-solid fa-bars"></i>
    </label> -->

  </div>




</div>


