<div class="modal-container">
    <div class="modal-header">
        <div class="modal-top">
            <!-- <img alt="BackgroundGradient" src="../../../assets/images/BackgroundGradient.png"> -->
            <img class="altsyslogo-alert" alt="AltsysLogo" src="../../../../assets//icons/Settings.svg"> 
            <h4>Company Name</h4>
        </div>
    </div>
    <div class="modal-body">
        Please Enter your company name to proceed
    </div>
    <div class="input-field">
        <input class="form-control" placeholder="Company Name" name="companyName" type="text" [(ngModel)]="companyName">
    </div>
    <div class="modal-footer">
        <button class="btn compare-btn" (click)="createQuotationService()" [disabled]="!companyName">Submit</button>
    </div>
</div>