<div class="container">
    <div *ngIf="routePath === 'home'">
        <span  class="text-header alignLeft">Trending Products</span>
        <!-- <span class="text-normal brown-color alignRight">
            See All
            <img alt="arrow-right-1" src="../../../../assets/icons/arrow-right-1.svg">
        </span> -->
    </div>

    



               <!-- Topic Cards -->
    <div id="cards_landscape_wrap-2">
        <div class="product-container">
            <div class="row">
                <ng-container *ngFor="let product of productList">
                    <div class="card-styling" [ngClass]=" (routePath === 'home')? 'col-xs-12 col-sm-6 col-md-3 col-lg-3' : 'col-xs-12 col-sm-6 col-md-3 col-lg-3'">
                        <div href="">
                            <card-flyer [product]="product" [routePath]="routePath"></card-flyer>
                            <!-- <div class="card-flyer">
                                <div class="text-box">
                                    <div class="image-box" (click)="navigateToProductDetails(product)">
                                        <img src="{{(product.imageURL && product.imageURL !== null) ? product.imageURL : 'https://csg1003200209655332.blob.core.windows.net/images/1681727933-Microsofticon.png'}}">
                                        
                                        <div class="overlay">
                                            <div class="review-container">
                                                <span>4.3</span>
                                                <mat-icon class="review-icon">star</mat-icon>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="text-container title-header">
                                        <div class="title-container">
                                            <h4>{{product.name}}</h4>
                                            <img src="../../../assets/icons/Bookmark.svg">
                                        </div>
                                        <p class="description-container">
                                            {{product.shortDescription}}
                                        </p>
                                        
                                        
                                    </div>
                                    <div class="text-container text-solution">
                                        <span class="text-style">
                                            <h6> Solution Category :</h6>
                                        </span>
                                        <div  class="solution-link">
                                            {{ (product.subcategories && product.subcategories.length > 0)? product.subcategories[0].name : ''}}
                                        </div>
                                        
                                    </div>
                                    <div class="button-container">
                                        <div class="product-actions">
                                            <button class="btn compare-btn">Add to compare</button>
                                            <button class="btn request-btn" (click)="requestQuote(product)">Request quote</button>
                                          </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>


    
</div>
