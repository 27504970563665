import { Component } from '@angular/core';

@Component({
  selector: 'compare-products-recently-searched',
  templateUrl: './compare-products-recently-searched.component.html',
  styleUrls: ['./compare-products-recently-searched.component.css']
})
export class CompareProductsRecentlySearchedComponent {

}
