<div class="customer-review-container">
    <div class="user-review-details-section">
        <div class="customer-detail-section">
            <span class="user-name">Richard Michael</span>
            <span class="user-organisation">Siemens</span>
        </div>
    
        <div class="vertical-line"></div>
    
        <div class="review-details-section">
            <div class="review-title-section">
                <div class="review-title">
                    <span>"</span>
                    <span>One Stop for Business and life</span>
                    <span>"</span>
                </div>
            
                <div class="star-rating">
                    <span class="star" *ngFor="let _ of [].constructor(5)">&#9733;</span>
                </div>
            </div>
        
            <div class="review-details">
                <span class="reviewed-time">Reviewed on 01 November 2023</span>
                <div class="verified-section">
                    <img class="SuccessTickIcon" src="../../../assets/icons/SuccessTickIcon.svg">
                    <span>verified</span>
                </div>
            </div>
        </div>
    </div>

    <div class="written-reivew">
        It has everything. We all know the necessity and value of PowerPoint, Word, and Excel, but in the post-Covid workplace, It goes above and beyond in providing best-in class team connectivity, collaboration platforms, remote functionality, and more. You might need to buy certain add-ons depending on your industry or function, but for the most part, Microsoft will have what you need.  The platform includes so much, that it seems challenging to even begin to use it all. You begin to question whether you should be using programs and platforms that you're not and...
    </div>

</div>