<!-- Main Homepage -->


<!-- <nav class="navbar fixed-top navbar-expand-lg navbar-dark bg-primary">
 <div class="container-fluid">
   <a class="navbar-brand" href="#">Brand</a>
  <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav">
      <span class="navbar-toggler-icon"></span>
   </button>
  <div class="collapse navbar-collapse" id="main_nav">
	<ul class="navbar-nav ms-auto">
	<li class="nav-item"><a class="nav-link" href="#"> Menu item </a></li>
	<li class="nav-item"><a class="nav-link" href="#"> Menu item </a></li>
	<li class="nav-item"><a class="nav-link" href="#"> Menu item </a></li>
	</ul>
  </div> 
 </div>
</nav> -->












    <!-- Header Component-->
    <!-- <nav id="navbar" class="fixed-top">
        <app-header (loginEvent)='loginEvent($event)' [userName]="userName"></app-header>
        <app-toolbar (menuToogleEvent)='menuToogleEvent($event)'></app-toolbar>
        <responsive-toolbar (menuToogleEvent)='menuToogleEvent($event)'></responsive-toolbar>
    </nav> -->
    <!-- Header Component-->

    <nav id="navbar" class="fixed-top">

        <app-header (loginEvent)='loginEvent($event)' [userName]="userName"></app-header>

        <!-- <app-toolbar (menuToogleEvent)='menuToogleEvent($event)'></app-toolbar> -->

        <responsive-toolbar (menuToogleEvent)='menuToogleEvent($event)'></responsive-toolbar>

    </nav>
    
    <!-- <app-toolbar></app-toolbar>
    <router-outlet></router-outlet> -->

    <!-- <img src="../../assets/Logos/skysecureLoader.gif"> -->
    <div id="content-holder">

    
        <!-- <ngx-spinner size="medium" type="ball-atom"
        template="<img src='../../assets/Logos/skysecureLoader.gif' />" ></ngx-spinner>-->
        <ngx-spinner size="medium" type="ball-atom"></ngx-spinner>

        <!-- template="<img src='https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FLdkMjHXjLQUdFtHCdojyPI%2FWebsite-design-iteration---NOV-'23%3Fpage-id%3D50%253A850%26type%3Ddesign%26node-id%3D50-851%26viewport%3D224%252C110%252C0.23%26t%3DrAiZzh0dw5H1vNgY-1%26scaling%3Dmin-zoom%26starting-point-node-id%3D50%253A851%26mode%3Ddesign' />" -->
        <!-- <iframe style='border: 1px solid rgba(0, 0, 0, 0.1);' width='800' height='450' src='https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FLdkMjHXjLQUdFtHCdojyPI%2FWebsite-design-iteration---NOV-'23%3Fpage-id%3D50%253A850%26type%3Ddesign%26node-id%3D50-851%26viewport%3D224%252C110%252C0.23%26t%3DrAiZzh0dw5H1vNgY-1%26scaling%3Dmin-zoom%26starting-point-node-id%3D50%253A851%26mode%3Ddesign' allowfullscreen></iframe> -->


        <!-- <ng-container *ngIf="(userDetails$ | async)">

        </ng-container> -->
    
        <!-- <ng-container *ngIf="userLoggedIn"> -->




        <ng-container>
            <div class="full-screen-style">
                <app-sidenav-wrapper [menuToogledVal]="menuToogled" [userLoggedIn]="userLoggedIn" [userRole]="userRole" [userRoleList]="userRoleList" [userName]="userName"></app-sidenav-wrapper>
            </div>
            
        </ng-container>
        

        <!-- Container If not Logged In -->

        <!-- <ng-container *ngIf="!userLoggedIn">

            <router-outlet></router-outlet>


        </ng-container> -->




       <div #footerElement id="footerElement" class="footerElement">
        <app-footer  ></app-footer>
       </div>
    </div>