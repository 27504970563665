import { Component } from '@angular/core';

@Component({
  selector: 'app-video-review',
  templateUrl: './video-review.component.html',
  styleUrls: ['./video-review.component.css']
})
export class VideoReviewComponent {

}
