import { Component,Input } from '@angular/core';
import {MatProgressBarModule} from '@angular/material/progress-bar';

@Component({
  selector: 'product-review-average',
  templateUrl: './product-review-average.component.html',
  styleUrls: ['./product-review-average.component.css'],
})
export class ProductReviewAverageComponent {

}
