
<div id="simple-chat-button--container">
    <a id="simple-chat-button--button" href="https://api.whatsapp.com/send?phone=+917353355526&amp;text={{whatsAppMessage}}" target="_blank" rel="nofollow" aria-label="WhatsApp Chat Button"></a>
    <span id="simple-chat-button--text">Chat with us</span>
</div>
<div class="product-card-container">
    <h6 class="search-result">Results ({{productsList.length}})</h6>
    <ng-container *ngFor="let item of productsList; let i=index">
        <div class="product-card-flyer-container">
            <div class="logo-holder">
                <div class="logo-box">
                    
                    <img alt="" src="{{(item.bannerLogo && item.bannerLogo !== null) ? item.bannerLogo : 'https://csg1003200209655332.blob.core.windows.net/images/1685441484-MicrosoftLogo_300X300.png'}}">
                </div>
                <div class="ratings-box">
                    <div class="rating-stars">
                        <mat-icon class="review-icon">star</mat-icon>
                        <mat-icon class="review-icon">star</mat-icon>
                        <mat-icon class="review-icon">star</mat-icon>
                        <mat-icon class="review-icon">star</mat-icon>
                        <mat-icon class="review-icon">star_border</mat-icon>
                    </div>
                    <div class="realize-review">
                        <img class="realize-review-icon" src="../../../../assets/Logos/SS logo.svg">
                        <p class="r4">(4.0 Reviews)</p>
                    </div>
                    <!-- <div>  
                        (4.0 Reviews)
                    </div> -->
                </div>
                <div class="add-to-compare-box">
                    <span>
                        <input type="checkbox" id="1"
                        [checked]="item.checked" (change)="addToCompare($event, item, i)"
                        > Add to Compare
                    </span>
                </div>
            </div>

            <!-- Content Holder -->
            <div class="content-holder">
                <div class="header">
                    <div class="header-title" (click)="navigateToProductDetails(item)">
                      
                        {{item.name}}
                    </div>
                    <!-- <mat-icon>bookmark_border</mat-icon> -->
                    <!-- <img class="bookmark-icon" src="../../../../assets/icons/Bookmark.svg"> -->
                </div>
                <div class="price"> 
                    <!-- <new price hover functions> -->

                    <ng-container *ngIf="item.isPerpetual ; else subscriptionProductsTemplate">
                        

                            <ng-container *ngIf="item.isCommercial ; else educationalProducts">
                                <div class="product-price-section">

                                </div>

                            </ng-container>
                            <ng-template #educationalProducts>
                                <div class="product-price-section">
                                    
                                    <p *ngIf="item.priceList && item.priceList.length>0 && item.priceList[0].discountRate != null"
                                        class="discounted-text">
                                        -{{item.priceList[0].discountRate}}%&nbsp;</p>
                                    <div class="price">
                                        <span class="price-symbol">&#8377; {{item.priceList[0].ERPPrice | number : '1.2-2'}} /
                                            {{item.priceList[0].priceType}}</span>
                                    </div>
                                     
                                    <p class="gst">(GST Extra)</p>
                                </div>
                                
                            </ng-template>

                    </ng-container>
                    <ng-template #subscriptionProductsTemplate>
                        <div class="product-price-section">
                
                            <p *ngIf="item.priceList && item.priceList.length>0 && item.priceList[0].discountRate != null"
                                class="discounted-text">
                                -{{item.displayDiscount}}%&nbsp;</p>
                            <div class="price"  (mouseenter)="showYearlyPrice(i)" (mouseleave)="showDiscountRate(i)">
                                <span class="price-symbol">&#8377; {{item.displayPrice | number : '1.2-2'}} /
                                    {{item.displayPriceType}}</span>
                            </div>
                             
                            <p class="gst">(GST Extra)</p>
                    
                        </div>
                        <span>
                            <span class="text-style-mrp">MRP:</span>
                            <span class="text-price strike-through" (mouseenter)="showYearlyPrice(i)" (mouseleave)="showDiscountRate(i)">
                                ₹ {{((item.priceList && item.priceList.length>0) ? item.displayERPPrice : '' ) | number : '1.2-2'}} /
                                {{item.displayPriceType}}</span>
                             <!-- <span class="text-price strike-through" *ngIf="item.priceList[0].isPerpetual" (mouseenter)="showYearlyPrice(i)"
                                (mouseleave)="showDiscountRate(i)">
                                ₹ {{((item.priceList && item.priceList.length>0) ? item.displayERPPrice : '' ) | number : '1.2-2'}} /
                                {{item.displayPriceType}}</span>
                            
                            <span class="text-price strike-through" *ngIf="item.priceList[0].isPerpetual && item.priceList[0].isEducational  ">
                                ₹ {{((item.priceList && item.priceList.length>0) ? item.displayERPPrice : '' ) | number : '1.2-2'}} /
                                {{item.displayPriceType}}</span>
                            <span class="text-price strike-through" *ngIf="item.priceList[0].isPerpetual && item.priceList[2]  ">
                                ₹ {{((item.priceList && item.priceList.length>0) ? item.displayERPPrice : '' ) | number : '1.2-2'}} /
                                {{item.displayPriceType}}</span>   -->
                           
                              
                        </span> 
                    </ng-template>  
                    <!-- <div class="product-price-section">
                
                        <p *ngIf="item.priceList && item.priceList.length>0 && item.priceList[0].discountRate != null"
                            class="discounted-text">
                
                            -{{item.displayDiscount}}%&nbsp;</p>
                           
                    
                
                
                        <div class="price"  (mouseenter)="showYearlyPrice(i)" (mouseleave)="showDiscountRate(i)">
                            <span class="price-symbol">&#8377; {{item.displayPrice | number : '1.2-2'}} /
                                {{item.displayPriceType}}</span>
                        </div>
                        <div class="price" *ngIf="item.priceList[0].isPerpetual" (mouseenter)="showYearlyPrice(i)" (mouseleave)="showDiscountRate(i)">
                            <span class="price-symbol">&#8377; {{item.displayPrice | number : '1.2-2'}} /
                                {{item.displayPriceType}}</span>
                        </div>

                        <div class="price"  *ngIf="item.priceList[0].isPerpetual && item.priceList[0].isEducational  " >
                            <span class="price-symbol">&#8377; {{item.displayPrice | number : '1.2-2'}} /
                                {{item.displayPriceType}}</span>
                        </div>

                        <div class="price"  *ngIf="item.priceList[0].isPerpetual && item.priceList[2]  " >
                            <span class="price-symbol">&#8377; {{item.displayPrice | number : '1.2-2'}} /
                                {{item.displayPriceType}}</span> 
                        </div> 
                         
                       

                        <p class="gst">(GST Extra)</p>
                
                    </div> -->
                    <!-- <span>
                        <span class="text-style-mrp">MRP:</span>
                        <span class="text-price strike-through" (mouseenter)="showYearlyPrice(i)" (mouseleave)="showDiscountRate(i)">
                            ₹ {{((item.priceList && item.priceList.length>0) ? item.displayERPPrice : '' ) | number : '1.2-2'}} /
                            {{item.displayPriceType}}</span>
                         <span class="text-price strike-through" *ngIf="item.priceList[0].isPerpetual" (mouseenter)="showYearlyPrice(i)"
                            (mouseleave)="showDiscountRate(i)">
                            ₹ {{((item.priceList && item.priceList.length>0) ? item.displayERPPrice : '' ) | number : '1.2-2'}} /
                            {{item.displayPriceType}}</span>
                        
                        <span class="text-price strike-through" *ngIf="item.priceList[0].isPerpetual && item.priceList[0].isEducational  ">
                            ₹ {{((item.priceList && item.priceList.length>0) ? item.displayERPPrice : '' ) | number : '1.2-2'}} /
                            {{item.displayPriceType}}</span>
                        <span class="text-price strike-through" *ngIf="item.priceList[0].isPerpetual && item.priceList[2]  ">
                            ₹ {{((item.priceList && item.priceList.length>0) ? item.displayERPPrice : '' ) | number : '1.2-2'}} /
                            {{item.displayPriceType}}</span>  
                       
                          
                    </span>  -->
                
                </div>
               
                <div class="solution-category">
                    <div class="text-style-bold">
                        Solution Category:
                    </div>
                    <div class="text-style" *ngIf="item.type=='product' || item.type=='productBundles' || item.type=='productBundleVariants'">
                       
                      
                       <!-- {{ (item.subcategories && item.subcategories.length > 0)? item.subcategories[0].name : ''}} -->
                       {{item.subcategories.name}} &nbsp; 
                       <ng-container *ngIf="(item.subCategories2 && item.subCategories2.length>0)">
                        | &nbsp; {{item.subCategories2[0].name}}
                       </ng-container>
                       
                    </div>
                    <div class="text-style" *ngIf="item.type=='productVariants'">
                   
                      
                        {{ (item.product.subCategories && item.product.subCategories.length > 0)? item.product.subCategories[0].name : ''}}
                        
                     </div>

                   
                </div>
                <ng-container *ngIf="item.description">
                    <div class="descr">
                        <div class="text-style-bold">
                            Description:
                        </div>
                        <div class="text-style">
                            {{item.shortDescription}}
                        </div>
                    </div>
                </ng-container>
                <div class="btn-holder">
                    <button class="btn primary-btn" (click)="navigateToProductDetails(item)">View Details</button>
                    <button class="btn secondary-btn" (click)="requestQuote(item)">Add to Cart</button>
                    
                </div>
            </div>
        </div>
        
    </ng-container>
    

