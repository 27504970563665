<div id="cards_landscape_wrap-2">

    <div class="cards_landscape_wrap-2 card-flyer">
        <div class="text-box">
            <div class="image-box" (click)="navigateToProductDetails(product)">
                <img alt="" class="img-style" src="{{(product.bannerLogo && product.bannerLogo !== null) ? product.bannerLogo : 'https://csg1003200209655332.blob.core.windows.net/images/1685441484-MicrosoftLogo_300X300.png'}}">
                <!-- <img src="../../../assets/images/mrr.png"> -->
                <div class="overlay">
                    <div class="review-container">
                        <span>4.0</span>
                        <mat-icon class="review-icon">star</mat-icon>
                    </div>
                    <!-- <div>
                        <span style="text-decoration: underline;">See Reviews</span>
                    </div> -->
                </div>
            </div>
            <!-- <div class="bg-image">

            </div> -->
            <div class="text-container title-header">
                <div class="title-container">
                    <h4>{{product.name}}</h4>
                    <!-- <img alt="BookmarkIcon" src="../../../assets/icons/Bookmark.svg"> -->
                </div>
                <!-- <p class="description-container">
                    {{product.shortDescription ? product.shortDescription : product.description}}
                </p> -->
                
                <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p> -->
            </div>
            <ng-container *ngIf="!(routePath === 'productBundles')">
                <div class="text-container text-solution">
                    <span class="text-style">
                        <h6> Solution Category :</h6>
                    </span>
                    <div  class="solution-link">
                        {{ (product.subcategories && product.subcategories.length > 0)? product.subcategories[0].name : ''}}

                        <ng-container *ngIf="(product.subCategories2 && product.subCategories2.length>0)">
                            &nbsp;  | &nbsp; {{product.subCategories2[0].name}}
                        </ng-container>
                    </div>
                    
                </div>
            </ng-container>
            
            <div class="button-container">
                <div class="product-actions">
                    <button class="btn secondary-btn" (click)="navigateToProductDetails(product)">View Details</button>
                    <!-- <button class="btn request-btn" (click)="requestQuote(product)">Request quote</button> -->
                    <button class="btn primary-btn" (click)="requestQuote(product)">Add To Cart</button>
                  </div>
            </div>
        </div>
    </div>

</div>

<!--  Login Alert Modal -->

<ng-container *ngIf="showModal">
    <!-- <div class="modal" [class.show]="showModal" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
 aria-hidden="true"> -->
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
			</div>
			<div class="modal-body">
				<div class="form-group">
					<div class="input-group">
						<span class="input-group-addon"><label for="editOwnerName">Deployment Name</label></span>
						
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal()">Close</button>
				<button type="button" class="btn btn-primary">Save changes</button>
			</div>
		</div>
	</div>
<!-- </div> -->


</ng-container>

<!--  Login Alert Modal Ends -->
        
    