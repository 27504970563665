<!-- <div>
  <p-dialog header="Get Free Call" [(visible)]="displayBasic" [modal]="true" [style]="{width: '50vw'}" [baseZIndex]="10000"
  [draggable]="false" [resizable]="false">

  <div class="form-body">

    <div class="row">
      <div class="col-md-12">
        
        <div class="form-group">
          <label id="email-label" for="email">Email</label>
          <input type="email" name="email" id="email" placeholder="Enter your email" class="form-control" required>
        </div>
        
      
      </div>
    </div>

    <div class="row textarea-style">
      <div class="col-md-12">
        <div class="form-group">
          <label>Leave Message</label>
          <textarea  id="comments" class="form-control" name="comment" placeholder="Enter your comment here..."></textarea>
        </div>
      </div>
    </div>
  </div>

  <ng-template pTemplate="footer">
      <p-button icon="pi pi-times" (click)="displayBasic=false" label="Cancel"></p-button>
      <p-button class="btn-send" icon="pi pi-check" (click)="sendEmail()" label="Send Email" styleClass="p-button-text"></p-button>
      
  </ng-template>
</p-dialog>
</div> -->

<div class="modal-container">
  <div class="modal-logo">
    <img class="company-logo" src="../../../../assets/Logos/skysecureLogo.png">
  </div>

  <div class="modal-header">
    Connect With Us!
  </div>
  <form [formGroup]="form">

    

    <div class="modal-body">

      

  

      <div class="email-style">
        <span class="p-float-label">
          <input 
            pInputText 
            id="emailId" 
            formControlName="emailId"
            [(ngModel)]="emailId" 
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.emailId.errors }"
            />
            <div *ngIf="submitted && f.emailId.errors" class="invalid-feedback">
              <div *ngIf="f.emailId.errors.required">Email is required</div>
              <div *ngIf="f.emailId.errors.email">Email is invalid</div>
            </div>
          <label htmlFor="emailId">Email ID</label>
        </span>
      </div>

      <div class="phoneno-style">
        <span class="p-float-label">
          <input 
            pInputText 
            id="phoneNo" 
            formControlName="phoneNo"
            [(ngModel)]="phoneNo" 
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.phoneNo.errors }"
            />
            <div *ngIf="submitted && f.phoneNo.errors" class="invalid-feedback">
              <div *ngIf="f.phoneNo.errors.required">Mobile Number is required</div>
            </div>
          <label htmlFor="phoneNo">Mobile Number</label>
        </span>
      </div>

      <div class="company-name-style">
        <span class="p-float-label">
          <input 
            pInputText 
            id="companyName" 
            formControlName="companyName"
            [(ngModel)]="companyName" />
          <label htmlFor="companyName">Company Name</label>
        </span>
      </div>

      <div class="message-text-style">
        <span class="p-float-label">
          <textarea 
            id="messageText" 
            rows="5" 
            cols="30" 
            formControlName="messageText"
            pInputTextarea 
            [(ngModel)]="messageText">
          </textarea>
          <label for="messageText">Message</label>
        </span>
      </div>


      <div class="modal-footer">
        <button type="button" (click) = onSubmit() class="btn primary-btn" >Submit</button>
        <button (click)="closeModal()" type="button" class="btn secondary-btn" aria-label="Close">
          <!-- <span aria-hidden="true">&times;</span> -->
          Cancel
        </button>
      </div>


    </div>



  </form>

</div>