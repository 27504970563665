<!-- <footer class="footer-container">

    <div class="footer-column p-4">



        <div class="section-01">
            <div class="logo">
                <img class="footer-logo" alt="" src="../../../assets/Logos/skysecureLogo.png">
            </div>
            <div class="heading">
                <p> Assess, Implement & Manage the best solutions!</p>
            </div>
        </div>
        <div class="section-02">
            <div class="sec02-heading">
                <h1>CONTACT US</h1>
            </div>
            <div class="phoneno">
                <div class="phone-icon"> <img alt=""  class="p-icon" src="../../../assets/icons/PhoneCall.svg"></div>
                <div class="phone-number"><span>(+91) 7353355526</span></div>
            </div>
            <div class="message">
                <div class="message-icon"> <img alt=""  class="p-icon" src="../../../assets/icons/Mail.svg"></div>
                <div class="email">
                    <div class="email-01">
                        <p>sales@skysecuretech.com</p>
                    </div>
                   
                </div>

            </div>
        </div>
        <div class="section-03">
            <div class="sec03-heading">
                <h2>QUICK LINKS</h2>
            </div>

            <div class="A">
                <span>FAQs</span>
            </div>
           
            <div class="C">
                <span>BLOGS</span>
            </div>
        </div>
        <div class="section-04">
            <div class="sec04-heading">
                <h1>SKYSECURE</h1>
            </div>
            <div class="location">
                <div class="location-icon"> <img alt="" class="p-icon" src="../../../assets/icons/Mappin.svg"></div>
                <div class="address"><span></span>AGR Plaza, 6/1, 3rd floor, Bellandur, Bengaluru, Karnataka 560103
                </div>
            </div>
        </div>
        <div class="section-05">
            <div class="sec05-heading">
                <h3>SOCIAL MEDIA</h3>
            </div>
            <div class="social-icons">

                <div class="icon-1" (mouseenter)="isHiddenTwitter = !isHiddenTwitter" (mouseleave)="isHiddenTwitter = !isHiddenTwitter">
                    <a href="https://twitter.com/SkysecureT?t=eIQh6SBZr52JEPj57ntPWw&s=09" target="_blank">
                        <ng-container *ngIf="isHiddenTwitter"><img class="p-icon-01" src="../../../assets/icons/TwitterIcon.svg"></ng-container>
                        <ng-container *ngIf="!isHiddenTwitter"><img alt="" class="p-icon-01" src="../../../assets/icons/TwitterXicon.svg"></ng-container>
                    </a>
                </div>
                <div class="icon-2" (mouseenter)="isHiddenLinkedIn = !isHiddenLinkedIn" (mouseleave)="isHiddenLinkedIn = !isHiddenLinkedIn">
                    <a href="https://www.linkedin.com/company/skysecure-technologies/" target="_blank">
                        <ng-container *ngIf="isHiddenLinkedIn"><img alt="" class="p-icon-02" src="../../../assets/icons/LinkedInIcon.svg">
                        </ng-container>
                        <ng-container *ngIf="!isHiddenLinkedIn"><img alt="" class="p-icon-02" src="../../../assets/icons/LinkedInWhiteIcon.svg">
                        </ng-container>
                    </a>
                </div>
                <div class="icon-3" (mouseenter)="isHiddenYouTube = !isHiddenYouTube" (mouseleave)="isHiddenYouTube = !isHiddenYouTube">
                    <a href="https://www.youtube.com/channel/UCdCps8R7SdXhW3YQnE6tsyg"    target="_blank">
                        <ng-container *ngIf="isHiddenYouTube"><img alt="" class="p-icon-02" src="../../../assets/icons/YoutubeIcon.svg"></ng-container>
                        <ng-container *ngIf="!isHiddenYouTube"><img alt="" class="p-icon-02" src="../../../assets/icons/YoutubeIcon-1.svg"></ng-container>
                    </a>
                </div>
            </div>
        </div>

    </div>




</footer> -->



<!-- alter footer -->
<footer>
    <div class="footer-container">
        <div class="top-section">
            <div class="logo-part">
                <div class="logo-container">
                    <img class="logo" src="../../../assets/Logos/skysecureLogo.png">
                </div>
                <div class="heading">
                    <p> Assess, Implement & Manage the best solutions!</p>
                </div>
            </div>
                    
        <div class="contact-us-container">
            <div class="headline">
                Contact Us
            </div>
            <div class="content-container">
                <div class="content-section">
                    <div class="icon-section">
                        <img class="icon" src="../../../assets/icons/PhoneCall.svg">
                    </div>
                    <div class="contact-us-content">(+91) 73533 55526</div>
                </div>
                <div class="content-section">
                    <div class="icon">
                        <img class="icon" src="../../../assets/icons/Mail.svg">
                    </div>
                    <div class="contact-us-content">sales@skysecuretech.com</div>
                </div>
            </div>
        </div>
        <!-- <div class="quick-links-container">
            <div class="headline">
                Quick Links
            </div>
            <ul class="quick-link-section">
                <li class="quick-links">FAQs</li>
                <li class="quick-links">NEWS</li>
                <li class="quick-links"><a routerLink="/blog">BLOGS</a></li>
            </ul>
        </div> -->
        <div class="address-container">
            <div class="headline">
                Locations
            </div>
            <div class="location-content-section">
                <div class="location-section">
                    <div class="address-type">
                        <p class="company">SKYSECURE TECHNOLOGIES PRIVATE LIMITED</p>
                        <!-- <img class="icon" src="../../../assets/icons/Mappin.svg"> -->
                        REGD. OFFICE ADDRESS
                    </div>
                    <div class="address">
                        
                        <p><img class="address-icon" src="../../../assets/icons/Mappin.svg">AGR Plaza, 6/1,
                        3rd floor, Bellandur,
                        Bengaluru, Karnataka-560103.</p>
                    </div>
                </div>
                <div class="location-section">
                    <div class="address-type">
                       
                       OTHER OFFICE ADDRESS
                    </div>
                    <div class="address">
                        <p> <img class="address-icon" src="../../../assets/icons/Mappin.svg">30 Cecil Street,
                        # 19-08, Prudential Tower,
                        Singapore-049712.</p>
                    </div>
                    <div class="address">
                        <p> <img class="address-icon" src="../../../assets/icons/Mappin.svg">3rd Floor, Karthika Towers, DB Road, RS Puram, Coimbatore 641002.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="social-media-container">
            <div class="headline">
                Social Media
            </div>
           
            <ul class="socialmedia-icons">
                
                <li >
                    <a class="text-link" target="_blank" href="https://twitter.com/SkysecureT?t=eIQh6SBZr52JEPj57ntPWw&s=09">
                      
                        <img alt="" class="hover-icon" src="../../../assets/icons/TwitterXicon.svg">
                    </a>
                </li>
                <li >
                    <a class="text-link" target="_blank" href="https://www.linkedin.com/company/skysecure-technologies/">
                       
                        <img alt="" class="hover-icon" src="../../../assets/icons/LinkedInWhiteIcon.svg">
                    </a>
                </li>
                <li >
                    <a class="text-link" target="_blank" href="https://www.youtube.com/channel/UCdCps8R7SdXhW3YQnE6tsyg">
                    
                       <img alt="" class="hover-icon" src="../../../assets/icons/YoutubeIcon-1.svg">
                    </a>
                </li>
            </ul>
        </div>
        </div>

        <div class="second-section">
            <div class="conditions-section">
                <div class="terms">
        
                    <a  [routerLink]="['/company/refund']" class="sect-head">Cancellation and Refund</a>
                </div>
                <div class="center">
        
                    <p>|</p>
                </div>
                <div class="terms">
        
                    <a  [routerLink]="['/company/terms']" class="sect-head">Terms of Use</a>
                </div>
                <div class="center">
        
                    <p>|</p>
                </div>
                <div class="terms">
        
                    <a  [routerLink]="['/company/privacy']" class="sect-head">Privacy</a>
                </div>
            </div>

            <!-- <div class="company-name">
                <p class="company-name-content">SKYSECURE TECHNOLOGIES PRIVATE LIMITED</p>
            </div> -->

            <div class="rights-reserved">
                <p class="rights-reserved-content"> &copy; Skysecure 2023    All Rights Reserved</p>
            </div>

        </div>

    </div>

</footer>