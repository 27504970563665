<!-- <div class="card-section">
    <div class="category-card card-item d-flex flex-column justify-content-center align-items-center m-2 p-2">
        <div class="card-container">
            <div class="ellipse p-3">
                <img src="../../../assets/icons/Users.svg" alt="Angular Grid: Getting Started">
                <img src="{{imageSrc}}">
            </div>
            <div class="j_Y">
                <span class="text-normal">
                    {{name}}
                </span>
            </div>
        </div>
    </div>
</div> -->
<div class="prod-card-section">
    <div class="prod-card-container">
        <div class="prod-img">
            <img class="prod-icon" src="{{imageSrc}}">
        </div>

        <div class="prod-header">
            <span class="text-normal-01">
                {{name}}
            </span>
        </div>
        <div class="view">
          
           <div class="view-cont">Explore</div>
           <div class="eye-icon">
            <img class="visibility" src="../../../assets/icons/arrow forward for primary button.svg">
           </div>
            </div>
            
    </div>
</div>
